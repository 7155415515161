.arrowMessage {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 1rem 0;
}

/* Variant Drawer */

.containerVariants {
  display: flex;
  align-items: center;
}

/* Edit Variation */

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  text-align: center;
}

.editVariation {
  height: 100%;
  width: 95%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Generate Variant */

.containerGenerate {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  height: 100%;
}

.congratsMessage {
  margin-top: 1.5rem;
  font-size: 21px;
  color: var(--sys-color-content-interactive);
  text-transform: uppercase;
  font-weight: 500;
}

.generateMessage {
  margin-top: 1.5rem;
  font-size: 17px;
  line-height: 24px;
}
