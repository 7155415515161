.textArea {
  position: relative;
  border-radius: var(--comp-textarea-border-radius);
  padding: var(--comp-textarea-padding-default);
}

.innerTextArea {
  width: 100%;
  border: none;
  resize: none;
  background: none;
  outline: none;
  -webkit-appearance: none;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

.label {
  position: absolute;
  background: var(--sys-color-surface-00);
  transition: all 0.3s ease;
  pointer-events: none;
  top: 5px;
  left: var(--sys-size-4);
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  font-weight: var(--sys-typography-label-xs-font-weight);
  line-height: var(--sys-typography-label-xs-line-height);
}

.innerTextArea::placeholder {
  color: var(--comp-textarea-color-foreground-placeholder);
}

textarea:focus ~ label,
textarea:not([value='']) ~ label,
textarea:placeholder-shown ~ label {
  transform: translateY(-14px);
}
