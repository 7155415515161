.alertBanner {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--comp-alert-banner-border-radius);
  padding: var(--comp-alert-banner-padding-default);
  gap: var(--comp-alert-banner-padding-gap);
  cursor: default;
}

.title {
  font-size: var(--sys-typography-body-md-font-size);
  text-decoration: var(--sys-typography-body-md-text-decoration);
  letter-spacing: var(--sys-typography-body-md-letter-spacing);
  font-weight: var(--sys-typography-body-md-font-weight);
  line-height: var(--sys-typography-body-md-line-height);
}

.cross {
  position: absolute;
  right: var(--comp-alert-banner-spacing-default);
}

.cross > * {
  height: var(--comp-alert-banner-size-icon);
  width: var(--comp-alert-banner-size-icon);
}
