.containerType {
  display: flex;
  border: 1px solid var(--sys-color-content-interactive);
  height: var(--sys-size-8);
  margin: 5px 0.5em;
  border-radius: 4px;
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
}
