.container {
  position: relative;
  cursor: pointer;
  outline: none;
}

.selected {
  position: absolute;
  top: 12px;
  left: 12px;
}

.picker {
  border-radius: var(--comp-picker-border-radius);
  object-fit: cover;
}
