/**
 * Do not edit directly
 * Generated on Tue, 04 Apr 2023 11:51:03 GMT
 */

:root {
  --sys-rotation-180: -180°;
  --sys-rotation-90: -90°;
  --sys-rotation-90: 90°;
  --sys-rotation-0: 0°;
  --sys-color-overlay-light: #ffffff99;
  --sys-color-overlay-dark: #00000099;
  --sys-color-status-interactive: #2563eb;
  --sys-color-status-info-light: #dbeafe;
  --sys-color-status-info-default: #3b82f6;
  --sys-color-status-info-dark: #2563eb;
  --sys-color-status-warning-light: #fef9c3;
  --sys-color-status-warning-dark: #ca8a04;
  --sys-color-status-warning-default: #ca8a04;
  --sys-color-status-success-light: #ecfccb;
  --sys-color-status-success-default: #84cc16;
  --sys-color-status-success-dark: #65a30d;
  --sys-color-status-danger-light: #ffe4e6;
  --sys-color-status-danger-default: #f43f5e;
  --sys-color-status-danger-dark: #e11d48;
  --sys-color-scroll-bar-default: #c2c7ce;
  --sys-color-divider-default: #c2c7ce;
  --sys-color-border-disabled: #f0f1f2;
  --sys-color-border-success: #84cc16;
  --sys-color-border-danger: #f43f5e;
  --sys-color-border-warning: #ca8a04;
  --sys-color-border-info: #3b82f6;
  --sys-color-border-interactive: #2563eb;
  --sys-color-border-focused: #2563eb;
  --sys-color-border-hover: #a4acb7;
  --sys-color-border-default: #f0f1f2;
  --sys-color-content-on-accent: #ffffff;
  --sys-color-content-disabled: #a4acb7;
  --sys-color-content-success: #84cc16;
  --sys-color-content-danger: #e11d48;
  --sys-color-content-warning: #ca8a04;
  --sys-color-content-info: #3b82f6;
  --sys-color-content-interactive: #2563eb;
  --sys-color-content-secondary: #4d6077;
  --sys-color-content-primary: #28313d;
  --sys-color-surface-00: #ffffff;
  --sys-color-surface-05: #a4acb7;
  --sys-color-surface-04: #c2c7ce;
  --sys-color-surface-03: #e0e3e6;
  --sys-color-surface-02: #f0f1f2;
  --sys-color-surface-01: #f7f8f9;
  --sys-color-background-accent: #4d6077;
  --sys-color-background-warning: #fef9c3;
  --sys-color-background-danger: #ffe4e6;
  --sys-color-background-info: #dbeafe;
  --sys-color-background-success: #ecfccb;
  --sys-color-background-interactive: #2563eb;
  --sys-shadow-box-md-type: dropShadow;
  --sys-shadow-box-md-color: #c2c7ce;
  --sys-shadow-box-md-spread: 0px;
  --sys-shadow-box-md-blur: 4px;
  --sys-shadow-box-md-y: 2px;
  --sys-shadow-box-md-x: 0px;
  --sys-shadow-box-sm-type: dropShadow;
  --sys-shadow-box-sm-color: #e0e3e6;
  --sys-shadow-box-sm-spread: 0px;
  --sys-shadow-box-sm-blur: 2px;
  --sys-shadow-box-sm-y: 2px;
  --sys-shadow-box-sm-x: 0px;
  --sys-shadow-box-lg-type: dropShadow;
  --sys-shadow-box-lg-color: rgba #e0e3e6;
  --sys-shadow-box-lg-spread: 0px;
  --sys-shadow-box-lg-blur: 16px;
  --sys-shadow-box-lg-y: 2px;
  --sys-shadow-box-lg-x: 0px;
  --sys-size-border-radius-rounded: 1000px;
  --sys-size-border-radius-lg: 16px;
  --sys-size-border-radius-md: 8px;
  --sys-size-border-radius-sm: 16px;
  --sys-size-border-radius-none: 0px;
  --sys-size-border-width-xl: 8px;
  --sys-size-border-width-lg: 4px;
  --sys-size-border-width-md: 2px;
  --sys-size-border-width-sm: 1px;
  --sys-size-3-5: 14px;
  --sys-size-2-5: 10px;
  --sys-size-1-5: 6px;
  --sys-size-px: 1px;
  --sys-size-0-5: 2px;
  --sys-size-none: 0px;
  --sys-size-96: 384px;
  --sys-size-80: 320px;
  --sys-size-72: 288px;
  --sys-size-64: 256px;
  --sys-size-60: 240px;
  --sys-size-56: 224px;
  --sys-size-52: 208px;
  --sys-size-48: 192px;
  --sys-size-44: 176px;
  --sys-size-40: 160px;
  --sys-size-36: 144px;
  --sys-size-32: 128px;
  --sys-size-28: 112px;
  --sys-size-24: 96px;
  --sys-size-20: 80px;
  --sys-size-18: 72px;
  --sys-size-16: 64px;
  --sys-size-14: 56px;
  --sys-size-13: 16px;
  --sys-size-12: 48px;
  --sys-size-11: 16px;
  --sys-size-10: 40px;
  --sys-size-9: 36px;
  --sys-size-8: 32px;
  --sys-size-7: 28px;
  --sys-size-6: 24px;
  --sys-size-5: 20px;
  --sys-size-4: 16px;
  --sys-size-3: 12px;
  --sys-size-2: 8px;
  --sys-size-1: 4px;
  --sys-typography-body-line-through-lg-text-decoration: line-through;
  --sys-typography-body-line-through-lg-text-case: none;
  --sys-typography-body-line-through-lg-paragraph-spacing: 0px;
  --sys-typography-body-line-through-lg-letter-spacing: 0px;
  --sys-typography-body-line-through-lg-font-size: 16px;
  --sys-typography-body-line-through-lg-line-height: 28px;
  --sys-typography-body-line-through-lg-font-weight: Regular;
  --sys-typography-body-line-through-lg-font-family: Poppins;
  --sys-typography-body-line-through-md-text-decoration: line-through;
  --sys-typography-body-line-through-md-text-case: none;
  --sys-typography-body-line-through-md-paragraph-spacing: 0px;
  --sys-typography-body-line-through-md-letter-spacing: 0px;
  --sys-typography-body-line-through-md-font-size: 14px;
  --sys-typography-body-line-through-md-line-height: 24px;
  --sys-typography-body-line-through-md-font-weight: Regular;
  --sys-typography-body-line-through-md-font-family: Poppins;
  --sys-typography-body-line-through-sm-text-decoration: line-through;
  --sys-typography-body-line-through-sm-text-case: none;
  --sys-typography-body-line-through-sm-paragraph-spacing: 0px;
  --sys-typography-body-line-through-sm-letter-spacing: 0px;
  --sys-typography-body-line-through-sm-font-size: 12px;
  --sys-typography-body-line-through-sm-line-height: 20px;
  --sys-typography-body-line-through-sm-font-weight: Regular;
  --sys-typography-body-line-through-sm-font-family: Poppins;
  --sys-typography-body-lg-text-decoration: none;
  --sys-typography-body-lg-text-case: none;
  --sys-typography-body-lg-paragraph-spacing: 0px;
  --sys-typography-body-lg-letter-spacing: 0px;
  --sys-typography-body-lg-font-size: 16px;
  --sys-typography-body-lg-line-height: 28px;
  --sys-typography-body-lg-font-weight: Regular;
  --sys-typography-body-lg-font-family: Poppins;
  --sys-typography-body-md-text-decoration: none;
  --sys-typography-body-md-text-case: none;
  --sys-typography-body-md-paragraph-spacing: 0px;
  --sys-typography-body-md-letter-spacing: 0px;
  --sys-typography-body-md-font-size: 14px;
  --sys-typography-body-md-line-height: 24px;
  --sys-typography-body-md-font-weight: Regular;
  --sys-typography-body-md-font-family: Poppins;
  --sys-typography-body-sm-text-decoration: none;
  --sys-typography-body-sm-text-case: none;
  --sys-typography-body-sm-paragraph-spacing: 0px;
  --sys-typography-body-sm-letter-spacing: 0px;
  --sys-typography-body-sm-font-size: 12px;
  --sys-typography-body-sm-line-height: 20px;
  --sys-typography-body-sm-font-weight: Regular;
  --sys-typography-body-sm-font-family: Poppins;
  --sys-typography-notification-xs-text-case: none;
  --sys-typography-notification-xs-text-decoration: none;
  --sys-typography-notification-xs-paragraph-spacing: 0px;
  --sys-typography-notification-xs-letter-spacing: 0px;
  --sys-typography-notification-xs-font-size: 9px;
  --sys-typography-notification-xs-line-height: 16px;
  --sys-typography-notification-xs-font-weight: SemiBold;
  --sys-typography-notification-xs-font-family: Poppins;
  --sys-typography-label-lg-text-decoration: none;
  --sys-typography-label-lg-text-case: none;
  --sys-typography-label-lg-paragraph-spacing: 0px;
  --sys-typography-label-lg-letter-spacing: 0px;
  --sys-typography-label-lg-font-size: 14px;
  --sys-typography-label-lg-line-height: 20px;
  --sys-typography-label-lg-font-weight: Medium;
  --sys-typography-label-lg-font-family: Poppins;
  --sys-typography-label-md-text-decoration: none;
  --sys-typography-label-md-text-case: none;
  --sys-typography-label-md-paragraph-spacing: 0px;
  --sys-typography-label-md-letter-spacing: 0px;
  --sys-typography-label-md-font-size: 12px;
  --sys-typography-label-md-line-height: 18px;
  --sys-typography-label-md-font-weight: Medium;
  --sys-typography-label-md-font-family: Poppins;
  --sys-typography-label-sm-underline-text-decoration: underline;
  --sys-typography-label-sm-underline-text-case: none;
  --sys-typography-label-sm-underline-paragraph-spacing: 0px;
  --sys-typography-label-sm-underline-letter-spacing: 0px;
  --sys-typography-label-sm-underline-font-size: 11px;
  --sys-typography-label-sm-underline-line-height: 16px;
  --sys-typography-label-sm-underline-font-weight: Medium;
  --sys-typography-label-sm-underline-font-family: Poppins;
  --sys-typography-label-sm-text-decoration: none;
  --sys-typography-label-sm-text-case: none;
  --sys-typography-label-sm-paragraph-spacing: 0px;
  --sys-typography-label-sm-letter-spacing: 0px;
  --sys-typography-label-sm-font-size: 11px;
  --sys-typography-label-sm-line-height: 16px;
  --sys-typography-label-sm-font-weight: Medium;
  --sys-typography-label-sm-font-family: Poppins;
  --sys-typography-label-xs-underline-text-case: none;
  --sys-typography-label-xs-underline-text-decoration: underline;
  --sys-typography-label-xs-underline-paragraph-spacing: 0px;
  --sys-typography-label-xs-underline-letter-spacing: 0px;
  --sys-typography-label-xs-underline-font-size: 9px;
  --sys-typography-label-xs-underline-line-height: 16px;
  --sys-typography-label-xs-underline-font-weight: Regular;
  --sys-typography-label-xs-underline-font-family: Poppins;
  --sys-typography-label-xs-text-case: none;
  --sys-typography-label-xs-text-decoration: none;
  --sys-typography-label-xs-paragraph-spacing: 0px;
  --sys-typography-label-xs-letter-spacing: 0px;
  --sys-typography-label-xs-font-size: 9px;
  --sys-typography-label-xs-line-height: 16px;
  --sys-typography-label-xs-font-weight: Regular;
  --sys-typography-label-xs-font-family: Poppins;
  --sys-typography-title-lg-text-case: none;
  --sys-typography-title-lg-text-decoration: none;
  --sys-typography-title-lg-paragraph-spacing: 0px;
  --sys-typography-title-lg-letter-spacing: 0px;
  --sys-typography-title-lg-font-size: 22px;
  --sys-typography-title-lg-line-height: 28px;
  --sys-typography-title-lg-font-weight: Bold;
  --sys-typography-title-lg-font-family: Poppins;
  --sys-typography-title-md-text-case: none;
  --sys-typography-title-md-text-decoration: none;
  --sys-typography-title-md-paragraph-spacing: 0px;
  --sys-typography-title-md-letter-spacing: 0px;
  --sys-typography-title-md-font-size: 16px;
  --sys-typography-title-md-line-height: 24px;
  --sys-typography-title-md-font-weight: SemiBold;
  --sys-typography-title-md-font-family: Poppins;
  --sys-typography-title-sm-text-case: none;
  --sys-typography-title-sm-text-decoration: none;
  --sys-typography-title-sm-paragraph-spacing: 0px;
  --sys-typography-title-sm-letter-spacing: 0px;
  --sys-typography-title-sm-font-size: 14px;
  --sys-typography-title-sm-line-height: 20px;
  --sys-typography-title-sm-font-weight: Medium;
  --sys-typography-title-sm-font-family: Poppins;
  --sys-typography-headline-lg-text-decoration: none;
  --sys-typography-headline-lg-text-case: none;
  --sys-typography-headline-lg-paragraph-spacing: 0px;
  --sys-typography-headline-lg-letter-spacing: 0px;
  --sys-typography-headline-lg-font-size: 32px;
  --sys-typography-headline-lg-line-height: 40px;
  --sys-typography-headline-lg-font-weight: Regular;
  --sys-typography-headline-lg-font-family: Poppins;
  --sys-typography-headline-md-text-case: none;
  --sys-typography-headline-md-text-decoration: none;
  --sys-typography-headline-md-paragraph-spacing: 0px;
  --sys-typography-headline-md-letter-spacing: 0px;
  --sys-typography-headline-md-font-size: 28px;
  --sys-typography-headline-md-line-height: 36px;
  --sys-typography-headline-md-font-weight: Regular;
  --sys-typography-headline-md-font-family: Poppins;
  --sys-typography-headline-sm-text-case: none;
  --sys-typography-headline-sm-text-decoration: none;
  --sys-typography-headline-sm-paragraph-spacing: 0px;
  --sys-typography-headline-sm-letter-spacing: 0px;
  --sys-typography-headline-sm-font-size: 24px;
  --sys-typography-headline-sm-line-height: 32px;
  --sys-typography-headline-sm-font-weight: Regular;
  --sys-typography-headline-sm-font-family: Poppins;
  --sys-typography-display-lg-text-case: none;
  --sys-typography-display-lg-text-decoration: none;
  --sys-typography-display-lg-paragraph-spacing: 0px;
  --sys-typography-display-lg-letter-spacing: 0px;
  --sys-typography-display-lg-font-size: 56px;
  --sys-typography-display-lg-line-height: 64px;
  --sys-typography-display-lg-font-weight: Regular;
  --sys-typography-display-lg-font-family: Poppins;
  --sys-typography-display-md-text-case: none;
  --sys-typography-display-md-text-decoration: none;
  --sys-typography-display-md-paragraph-spacing: 0px;
  --sys-typography-display-md-letter-spacing: 0px;
  --sys-typography-display-md-font-size: 48px;
  --sys-typography-display-md-line-height: 44px;
  --sys-typography-display-md-font-weight: Regular;
  --sys-typography-display-md-font-family: Poppins;
  --sys-typography-display-sm-text-case: none;
  --sys-typography-display-sm-text-decoration: none;
  --sys-typography-display-sm-paragraph-spacing: 0px;
  --sys-typography-display-sm-letter-spacing: 0px;
  --sys-typography-display-sm-font-size: 36px;
  --sys-typography-display-sm-line-height: 40px;
  --sys-typography-display-sm-font-weight: Regular;
  --sys-typography-display-sm-font-family: Poppins;
  --opacity-p90: 90%;
  --opacity-p80: 80%;
  --opacity-p70: 70%;
  --opacity-p60: 60%;
  --opacity-p50: 50%;
  --opacity-p40: 40%;
  --opacity-p30: 30%;
  --opacity-p10: 10%;
  --opacity-p20: 20%;
  --ref-font-letter-spacing-none: 0px;
  --ref-font-paragraph-spacing-none: 0px;
  --ref-font-line-height-rem-7-5: 120px;
  --ref-font-line-height-rem-5: 80px;
  --ref-font-line-height-rem-4: 64px;
  --ref-font-line-height-rem-2-75: 44px;
  --ref-font-line-height-rem-2-5: 40px;
  --ref-font-line-height-rem-2-25: 36px;
  --ref-font-line-height-rem-2: 32px;
  --ref-font-line-height-rem-1-75: 28px;
  --ref-font-line-height-rem-1-5: 24px;
  --ref-font-line-height-rem-1-25: 20px;
  --ref-font-line-height-rem-1-125: 18px;
  --ref-font-weight-700: Bold;
  --ref-font-weight-600: SemiBold;
  --ref-font-weight-500: Medium;
  --ref-font-weight-400: Regular;
  --ref-font-size-rem-6: 96px;
  --ref-font-size-rem-4: 64px;
  --ref-font-size-rem-3-5: 56px;
  --ref-font-size-rem-3: 48px;
  --ref-font-size-rem-2-25: 36px;
  --ref-font-size-rem-2: 32px;
  --ref-font-size-rem-1-75: 28px;
  --ref-font-size-rem-1-5: 24px;
  --ref-font-size-rem-1-375: 22px;
  --ref-font-size-rem-1-25: 20px;
  --ref-font-size-rem-0-875: 14px;
  --ref-font-size-rem-0-75: 12px;
  --ref-font-size-rem-0-6875: 11px;
  --ref-font-size-rem-0-5625: 9px;
  --ref-font-size-rem-0-5: 16px;
  --ref-font-family-poppins: Poppins;
  --ref-font-text-decoration-line-through: line-through;
  --ref-font-text-decoration-underline: underline;
  --ref-font-text-decoration-none: none;
  --ref-font-text-case-uppercase: uppercase;
  --ref-font-text-case-none: none;
  --ref-root-size: 16px;
  --ref-size-rem-24: 384px;
  --ref-size-rem-20: 320px;
  --ref-size-rem-18: 288px;
  --ref-size-rem-16: 256px;
  --ref-size-rem-15: 240px;
  --ref-size-rem-14: 224px;
  --ref-size-rem-13: 208px;
  --ref-size-rem-12: 192px;
  --ref-size-rem-11: 176px;
  --ref-size-rem-10: 160px;
  --ref-size-rem-9: 144px;
  --ref-size-rem-8: 128px;
  --ref-size-rem-7-5: 120px;
  --ref-size-rem-7: 112px;
  --ref-size-rem-6: 96px;
  --ref-size-rem-5: 80px;
  --ref-size-rem-4-5: 72px;
  --ref-size-rem-4: 64px;
  --ref-size-rem-3-5: 56px;
  --ref-size-rem-3-75: 60px;
  --ref-size-rem-3-25: 52px;
  --ref-size-rem-3: 48px;
  --ref-size-rem-2-75: 44px;
  --ref-size-rem-2-5: 40px;
  --ref-size-rem-2-25: 36px;
  --ref-size-rem-2: 32px;
  --ref-size-rem-1-875: 30px;
  --ref-size-rem-1-75: 28px;
  --ref-size-rem-1-5: 24px;
  --ref-size-rem-1-375: 22px;
  --ref-size-rem-1-125: 18px;
  --ref-size-rem-1-25: 20px;
  --ref-size-rem-1: 16px;
  --ref-size-rem-0-875: 14px;
  --ref-size-rem-0-75: 12px;
  --ref-size-rem-0-625: 10px;
  --ref-size-rem-0-6875: 11px;
  --ref-size-rem-0-5625: 9px;
  --ref-size-rem-0-5: 8px;
  --ref-size-rem-0-25: 4px;
  --ref-size-rem-0-375: 6px;
  --ref-size-rem-0-125: 2px;
  --ref-size-rem-0-0625: 1px;
  --ref-color-rose-900: #881337;
  --ref-color-rose-800: #9f1239;
  --ref-color-rose-700: #be123c;
  --ref-color-rose-600: #e11d48;
  --ref-color-rose-500: #f43f5e;
  --ref-color-rose-400: #fb7185;
  --ref-color-rose-300: #fda4af;
  --ref-color-rose-200: #fecdd3;
  --ref-color-rose-100: #ffe4e6;
  --ref-color-rose-50: #fff1f2;
  --ref-color-pink-900: #831843;
  --ref-color-pink-800: #9d174d;
  --ref-color-pink-700: #be185d;
  --ref-color-pink-600: #db2777;
  --ref-color-pink-500: #ec4899;
  --ref-color-pink-400: #f472b6;
  --ref-color-pink-300: #f9a8d4;
  --ref-color-pink-200: #fbcfe8;
  --ref-color-pink-100: #fce7f3;
  --ref-color-pink-50: #fdf2f8;
  --ref-color-fuchsia-900: #701a75;
  --ref-color-fuchsia-800: #86198f;
  --ref-color-fuchsia-700: #a21caf;
  --ref-color-fuchsia-600: #c026d3;
  --ref-color-fuchsia-500: #d946ef;
  --ref-color-fuchsia-400: #e879f9;
  --ref-color-fuchsia-300: #f0abfc;
  --ref-color-fuchsia-200: #f5d0fe;
  --ref-color-fuchsia-100: #fae8ff;
  --ref-color-fuchsia-50: #fdf4ff;
  --ref-color-violet-900: #4c1d95;
  --ref-color-violet-800: #5b21b6;
  --ref-color-violet-700: #6d28d9;
  --ref-color-violet-600: #7c3aed;
  --ref-color-violet-500: #8b5cf6;
  --ref-color-violet-400: #a78bfa;
  --ref-color-violet-300: #c4b5fd;
  --ref-color-violet-200: #ddd6fe;
  --ref-color-violet-100: #ede9fe;
  --ref-color-violet-50: #f5f3ff;
  --ref-color-indigo-900: #312e81;
  --ref-color-indigo-800: #3730a3;
  --ref-color-indigo-700: #4338ca;
  --ref-color-indigo-600: #4f46e5;
  --ref-color-indigo-500: #6366f1;
  --ref-color-indigo-400: #818cf8;
  --ref-color-indigo-300: #a5b4fc;
  --ref-color-indigo-200: #c7d2fe;
  --ref-color-indigo-100: #e0e7ff;
  --ref-color-indigo-50: #eef2ff;
  --ref-color-blue-900: #1e3a8a;
  --ref-color-blue-800: #1e40af;
  --ref-color-blue-700: #1d4ed8;
  --ref-color-blue-600: #2563eb;
  --ref-color-blue-500: #3b82f6;
  --ref-color-blue-400: #60a5fa;
  --ref-color-blue-300: #93c5fd;
  --ref-color-blue-200: #bfdbfe;
  --ref-color-blue-100: #dbeafe;
  --ref-color-blue-50: #eff6ff;
  --ref-color-sky-900: #0c4a6e;
  --ref-color-sky-800: #075985;
  --ref-color-sky-700: #0369a1;
  --ref-color-sky-600: #0284c7;
  --ref-color-sky-500: #0ea5e9;
  --ref-color-sky-400: #38bdf8;
  --ref-color-sky-300: #7dd3fc;
  --ref-color-sky-200: #bae6fd;
  --ref-color-sky-100: #e0f2fe;
  --ref-color-sky-50: #f0f9ff;
  --ref-color-cyan-900: #164e63;
  --ref-color-cyan-800: #155e75;
  --ref-color-cyan-700: #0e7490;
  --ref-color-cyan-600: #0891b2;
  --ref-color-cyan-500: #06b6d4;
  --ref-color-cyan-400: #22d3ee;
  --ref-color-cyan-300: #67e8f9;
  --ref-color-cyan-200: #a5f3fc;
  --ref-color-cyan-100: #cffafe;
  --ref-color-cyan-50: #ecfeff;
  --ref-color-teal-900: #134e4a;
  --ref-color-teal-800: #115e59;
  --ref-color-teal-700: #0f766e;
  --ref-color-teal-600: #0d9488;
  --ref-color-teal-500: #14b8a6;
  --ref-color-teal-400: #2dd4bf;
  --ref-color-teal-300: #5eead4;
  --ref-color-teal-200: #99f6e4;
  --ref-color-teal-100: #ccfbf1;
  --ref-color-teal-50: #f0fdfa;
  --ref-color-emerald-900: #064e3b;
  --ref-color-emerald-800: #065f46;
  --ref-color-emerald-700: #047857;
  --ref-color-emerald-600: #059669;
  --ref-color-emerald-500: #10b981;
  --ref-color-emerald-400: #34d399;
  --ref-color-emerald-300: #6ee7b7;
  --ref-color-emerald-200: #a7f3d0;
  --ref-color-emerald-100: #d1fae5;
  --ref-color-emerald-50: #ecfdf5;
  --ref-color-green-900: #14532d;
  --ref-color-green-800: #166534;
  --ref-color-green-700: #15803d;
  --ref-color-green-600: #16a34a;
  --ref-color-green-500: #22c55e;
  --ref-color-green-400: #4ade80;
  --ref-color-green-300: #86efac;
  --ref-color-green-200: #bbf7d0;
  --ref-color-green-100: #dcfce7;
  --ref-color-green-50: #f0fdf4;
  --ref-color-lime-900: #365314;
  --ref-color-lime-800: #3f6212;
  --ref-color-lime-700: #4d7c0f;
  --ref-color-lime-600: #65a30d;
  --ref-color-lime-500: #84cc16;
  --ref-color-lime-400: #a3e635;
  --ref-color-lime-300: #bef264;
  --ref-color-lime-200: #d9f99d;
  --ref-color-lime-100: #ecfccb;
  --ref-color-lime-50: #f7fee7;
  --ref-color-yellow-900: #713f12;
  --ref-color-yellow-800: #854d0e;
  --ref-color-yellow-700: #a16207;
  --ref-color-yellow-600: #ca8a04;
  --ref-color-yellow-500: #eab308;
  --ref-color-yellow-400: #facc15;
  --ref-color-yellow-300: #fde047;
  --ref-color-yellow-200: #fef08a;
  --ref-color-yellow-100: #fef9c3;
  --ref-color-yellow-50: #fefce8;
  --ref-color-amber-900: #78350f;
  --ref-color-amber-800: #92400e;
  --ref-color-amber-700: #b45309;
  --ref-color-amber-600: #d97706;
  --ref-color-amber-500: #f59e0b;
  --ref-color-amber-400: #fbbf24;
  --ref-color-amber-300: #fcd34d;
  --ref-color-amber-200: #fde68a;
  --ref-color-amber-100: #fef3c7;
  --ref-color-amber-50: #fffbeb;
  --ref-color-orange-900: #7c2d12;
  --ref-color-orange-800: #9a3412;
  --ref-color-orange-700: #c2410c;
  --ref-color-orange-600: #ea580c;
  --ref-color-orange-500: #f97316;
  --ref-color-orange-400: #fb923c;
  --ref-color-orange-300: #fdba74;
  --ref-color-orange-200: #fed7aa;
  --ref-color-orange-100: #ffedd5;
  --ref-color-orange-50: #fff7ed;
  --ref-color-red-900: #7f1d1d;
  --ref-color-red-800: #991b1b;
  --ref-color-red-700: #b91c1c;
  --ref-color-red-600: #dc2626;
  --ref-color-red-500: #ef4444;
  --ref-color-red-400: #f87171;
  --ref-color-red-300: #fca5a5;
  --ref-color-red-200: #fecaca;
  --ref-color-red-100: #fee2e2;
  --ref-color-red-50: #fef2f2;
  --ref-color-stone-900: #1c1917;
  --ref-color-stone-800: #292524;
  --ref-color-stone-700: #44403c;
  --ref-color-stone-600: #57534e;
  --ref-color-stone-500: #78716c;
  --ref-color-stone-400: #a8a29e;
  --ref-color-stone-300: #d6d3d1;
  --ref-color-stone-200: #e7e5e4;
  --ref-color-stone-100: #f5f5f4;
  --ref-color-stone-50: #fafaf9;
  --ref-color-neutral-900: #171717;
  --ref-color-neutral-800: #262626;
  --ref-color-neutral-700: #404040;
  --ref-color-neutral-600: #525252;
  --ref-color-neutral-500: #737373;
  --ref-color-neutral-400: #a3a3a3;
  --ref-color-neutral-300: #d4d4d4;
  --ref-color-neutral-200: #e5e5e5;
  --ref-color-neutral-100: #f5f5f5;
  --ref-color-neutral-50: #fafafa;
  --ref-color-zinc-900: #18181b;
  --ref-color-zinc-800: #27272a;
  --ref-color-zinc-700: #3f3f46;
  --ref-color-zinc-600: #52525b;
  --ref-color-zinc-500: #71717a;
  --ref-color-zinc-400: #a1a1aa;
  --ref-color-zinc-300: #d4d4d8;
  --ref-color-zinc-200: #e4e4e7;
  --ref-color-zinc-100: #f4f4f5;
  --ref-color-zinc-50: #fafafa;
  --ref-color-gray-900: #111827;
  --ref-color-gray-800: #1f2937;
  --ref-color-gray-700: #374151;
  --ref-color-gray-600: #4b5563;
  --ref-color-gray-500: #6b7280;
  --ref-color-gray-400: #9ca3af;
  --ref-color-gray-300: #d1d5db;
  --ref-color-gray-200: #e5e7eb;
  --ref-color-gray-100: #f3f4f6;
  --ref-color-gray-50: #f9fafb;
  --ref-color-slate-900: #0f172a;
  --ref-color-slate-800: #1e293b;
  --ref-color-slate-700: #334155;
  --ref-color-slate-600: #475569;
  --ref-color-slate-500: #64748b;
  --ref-color-slate-400: #94a3b8;
  --ref-color-slate-300: #cbd5e1;
  --ref-color-slate-200: #e2e8f0;
  --ref-color-slate-100: #f1f5f9;
  --ref-color-slate-50: #f8fafc;
  --ref-color-purple-900: #581c87;
  --ref-color-purple-800: #6b21a8;
  --ref-color-purple-700: #7e22ce;
  --ref-color-purple-600: #9333ea;
  --ref-color-purple-500: #a855f7;
  --ref-color-purple-400: #c084fc;
  --ref-color-purple-300: #d8b4fe;
  --ref-color-purple-200: #e9d5ff;
  --ref-color-purple-100: #f3e8ff;
  --ref-color-purple-50: #faf5ff;
  --ref-color-pure-black: #000000;
  --ref-color-pure-white: #ffffff;
  --ref-color-grey-cold-900: #171c23;
  --ref-color-grey-cold-800: #28313d;
  --ref-color-grey-cold-700: #3a4859;
  --ref-color-grey-cold-600: #4d6077;
  --ref-color-grey-cold-500: #69788d;
  --ref-color-grey-cold-400: #8792a1;
  --ref-color-grey-cold-300: #a4acb7;
  --ref-color-grey-cold-200: #c2c7ce;
  --ref-color-grey-cold-100: #e0e3e6;
  --ref-color-grey-cold-50: #f0f1f2;
  --ref-color-grey-cold-25: #f7f8f9;
  --ref-color-brand-tactill-green: #16ca23;
  --st-layer-color-neutral-disabled: #f7f8f9;
  --st-layer-color-neutral-loading: #ffffff99;
  --st-layer-color-neutral-focused: #0000001f;
  --st-layer-color-neutral-press: #0000001f;
  --st-layer-color-neutral-hover: #0000001f;
  --st-layer-color-neutral-default: #2563eb00;
  --st-layer-color-danger-disabled: #e0e3e6;
  --st-layer-color-danger-focused: #e11d481f;
  --st-layer-color-danger-press: #e11d481f;
  --st-layer-color-danger-hover: #e11d4814;
  --st-layer-color-danger-default: #2563eb00;
  --st-layer-color-default-disabled: #f7f8f9;
  --st-layer-color-default-focused: #2563eb14;
  --st-layer-color-default-press: #2563eb14;
  --st-layer-color-default-hover: #2563eb14;
  --st-layer-color-default-default: #2563eb00;
  --padding-top: 16px;
  --comp-intercom-trigger-border-radius: 1000px;
  --comp-intercom-trigger-color-foreground-disabled: #a4acb7;
  --comp-intercom-trigger-color-foreground-danger: #e11d48;
  --comp-intercom-trigger-color-foreground-tertiary: #2563eb;
  --comp-intercom-trigger-color-foreground-secondary: #2563eb;
  --comp-intercom-trigger-color-foreground-primary: #ffffff;
  --comp-intercom-trigger-color-border-disabled: #f0f1f2;
  --comp-intercom-trigger-color-border-secondary: #2563eb;
  --comp-intercom-trigger-color-background-danger-secondary: #fff1f2;
  --comp-intercom-trigger-color-background-danger-primary: #f43f5e;
  --comp-intercom-trigger-color-background-disabled: #f7f8f9;
  --comp-intercom-trigger-color-background-default-secondary: #eff6ff;
  --comp-intercom-trigger-color-background-default-primary: #2563eb;
  --comp-intercom-trigger-size-width-lg: 48px;
  --comp-intercom-trigger-size-width-md: 40px;
  --comp-intercom-trigger-size-width-sm: 32px;
  --comp-intercom-trigger-size-icon-lg: 16px;
  --comp-intercom-trigger-size-icon-md: 16px;
  --comp-intercom-trigger-size-icon-sm: 12px;
  --comp-intercom-trigger-size-height-lg: 48px;
  --comp-intercom-trigger-size-height-md: 40px;
  --comp-intercom-trigger-size-height-sm: 32px;
  --comp-notching-color-border: #f0f1f2;
  --comp-notching-color-background: #ffffff;
  --comp-notching-size-height-default: 12px;
  --comp-floatin-list-padding-left: 0px;
  --comp-floatin-list-padding-right: 0px;
  --comp-floatin-list-padding-bottom: 8px;
  --comp-floatin-list-padding-top: 8px;
  --comp-floatin-list-padding-gap: 0px;
  --comp-guidance-arrow-spacing-top-sm: 8px;
  --comp-guidance-arrow-spacing-top-md: 16px;
  --comp-guidance-arrow-size-width-md: 20px;
  --comp-guidance-arrow-size-width-sm: 10px;
  --comp-guidance-arrow-size-height-md: 80px;
  --comp-guidance-arrow-size-height-sm: 40px;
  --comp-guidance-arrow-color-foreground: #4d6077;
  --comp-tooltip-padding-right: 8px;
  --comp-tooltip-padding-left: 8px;
  --comp-tooltip-padding-bottom: 8px;
  --comp-tooltip-padding-top: 8px;
  --comp-tooltip-padding-gap: 4px;
  --comp-tooltip-border-radius: 16px;
  --comp-tooltip-color-foreground-text: #ffffff;
  --comp-tooltip-color-background: #0f172a;
  --comp-floating-list-shadow-type: dropShadow;
  --comp-floating-list-shadow-color: #c2c7ce;
  --comp-floating-list-shadow-spread: 0px;
  --comp-floating-list-shadow-blur: 4px;
  --comp-floating-list-shadow-y: 2px;
  --comp-floating-list-shadow-x: 0px;
  --comp-floating-list-border-radius: 8px;
  --comp-input-padding-gap: 4px;
  --comp-input-padding-right: 16px;
  --comp-input-padding-left: 16px;
  --comp-input-border-radius: 8px;
  --comp-input-color-icon-error: #e11d48;
  --comp-input-color-icon-disabled: #a4acb7;
  --comp-input-color-icon-default: #94a3b8;
  --comp-input-color-helper-error: #e11d48;
  --comp-input-color-helper-disabled: #a4acb7;
  --comp-input-color-helper-default: #4d6077;
  --comp-input-color-border-disabled: #f0f1f2;
  --comp-input-color-border-error: #f43f5e;
  --comp-input-color-border-focused: #2563eb;
  --comp-input-color-border-hover: #a4acb7;
  --comp-input-color-border-default: #f0f1f2;
  --comp-input-color-foreground-error: #e11d48;
  --comp-input-color-foreground-text-input: #28313d;
  --comp-input-color-foreground-placeholder: #4d6077;
  --comp-input-color-foreground-disabled: #a4acb7;
  --comp-input-color-label-disabled: #a4acb7;
  --comp-input-color-label-default: #4d6077;
  --comp-input-size-height: 40px;
  --comp-input-size-icon: 16px;
  --comp-scroll-bar-color: #e0e3e6;
  --comp-switch-size-md-background-widht: 40px;
  --comp-switch-size-md-background-height: 24px;
  --comp-switch-size-md-foreground: 16px;
  --comp-switch-size-lg-background-width: 16px;
  --comp-switch-size-lg-background-height: 32px;
  --comp-switch-size-lg-foreground: 24px;
  --comp-switch-color-foreground: #ffffff;
  --comp-switch-color-background-off: #a4acb7;
  --comp-switch-color-background-disabled: #f7f8f9;
  --comp-switch-color-background-on: #2563eb;
  --comp-tab-index-padding-gap: 0px;
  --comp-tab-index-padding-default: 1px;
  --comp-tab-index-item-color-foreground: #28313d;
  --comp-tab-index-item-color-background-selected: #ffffff;
  --comp-tab-index-item-color-background-default: #ffffff;
  --comp-tab-index-item-border-radius: 8px;
  --comp-tab-index-item-size-icon: 16px;
  --comp-tab-index-item-size-lg: 48px;
  --comp-tab-index-item-size-md: 40px;
  --comp-tab-index-item-size-sm: 32px;
  --comp-tab-list-padding-gap: 0px;
  --comp-tab-list-padding-default: 1px;
  --comp-tab-list-item-color-foreground-disabled: #a4acb7;
  --comp-tab-list-item-color-foreground-active: #28313d;
  --comp-tab-list-item-color-foreground-hover: #4d6077;
  --comp-tab-list-item-color-foreground-default: #4d6077;
  --comp-tab-list-item-border-radius-lg: 8px;
  --comp-tab-list-item-border-radius-md: 8px;
  --comp-tab-list-item-border-radius-sm: 16px;
  --comp-tab-list-item-padding-lg: 16px;
  --comp-tab-list-item-padding-md: 16px;
  --comp-tab-list-item-padding-sm: 8px;
  --comp-tab-list-item-height-lg: 48px;
  --comp-tab-list-item-height-md: 40px;
  --comp-tab-list-item-height-sm: 32px;
  --comp-attention-box-spacing-gap: 8px;
  --comp-attention-box-padding-right: 8px;
  --comp-attention-box-padding-left: 16px;
  --comp-attention-box-padding-bottom: 8px;
  --comp-attention-box-padding-top: 8px;
  --comp-attention-box-color-foreground-danger: #f43f5e;
  --comp-attention-box-color-foreground-warning: #ca8a04;
  --comp-attention-box-color-foreground-success: #84cc16;
  --comp-attention-box-color-foreground-info: #3b82f6;
  --comp-attention-box-color-lead-icon-danger: #f43f5e;
  --comp-attention-box-color-lead-icon-warning: #ca8a04;
  --comp-attention-box-color-lead-icon-success: #84cc16;
  --comp-attention-box-color-lead-icon-info: #3b82f6;
  --comp-attention-box-color-border-danger: #f43f5e;
  --comp-attention-box-color-border-warning: #ca8a04;
  --comp-attention-box-color-border-success: #84cc16;
  --comp-attention-box-color-border-info: #3b82f6;
  --comp-attention-box-color-background-danger: #ffe4e6;
  --comp-attention-box-color-background-warning: #fef9c3;
  --comp-attention-box-color-background-success: #ecfccb;
  --comp-attention-box-color-background-info: #dbeafe;
  --comp-attention-box-border-radius: 4px;
  --comp-attention-box-border-width: 1px;
  --comp-attention-box-size-icon: 16px;
  --comp-toast-size-icon: 24px;
  --comp-toaster-shadow-type: dropShadow;
  --comp-toaster-shadow-color: #c2c7ce;
  --comp-toaster-shadow-spread: 0px;
  --comp-toaster-shadow-blur: 4px;
  --comp-toaster-shadow-y: 2px;
  --comp-toaster-shadow-x: 0px;
  --comp-toaster-color-foreground-icon-success: #84cc16;
  --comp-toaster-color-foreground-icon-danger: #f43f5e;
  --comp-toaster-color-foreground-icon-warning: #ca8a04;
  --comp-toaster-color-foreground-icon-info: #3b82f6;
  --comp-toaster-color-foreground-text: #ffffff;
  --comp-toaster-color-background: #0f172a;
  --comp-toaster-border-radius: 16px;
  --comp-toaster-padding-left: 16px;
  --comp-toaster-padding-right: 16px;
  --comp-toaster-padding-bottom: 8px;
  --comp-toaster-padding-top: 8px;
  --comp-toaster-padding-gap: 16px;
  --comp-alert-banner-color-foreground-success: #84cc16;
  --comp-alert-banner-color-foreground-warning: #ca8a04;
  --comp-alert-banner-color-foreground-danger: #e11d48;
  --comp-alert-banner-color-foreground-info: #2563eb;
  --comp-alert-banner-color-border-success: #84cc16;
  --comp-alert-banner-color-border-warning: #ca8a04;
  --comp-alert-banner-color-border-danger: #f43f5e;
  --comp-alert-banner-color-border-info: #3b82f6;
  --comp-alert-banner-color-background-danger: #ffe4e6;
  --comp-alert-banner-color-background-warning: #fef9c3;
  --comp-alert-banner-color-background-success: #ecfccb;
  --comp-alert-banner-color-background-info: #dbeafe;
  --comp-alert-banner-border-radius: 8px;
  --comp-alert-banner-padding-gap: 8px;
  --comp-alert-banner-padding-left: 16px;
  --comp-alert-banner-padding-right: 8px;
  --comp-alert-banner-padding-default: 8px;
  --comp-alert-banner-size-height: 48px;
  --comp-alert-banner-size-icon: 24px;
  --comp-progress-bar-color-fill: #2563eb;
  --comp-progress-bar-color-base: #f0f1f2;
  --comp-progress-bar-border-radius: 1000px;
  --comp-progress-bar-height: 16px;
  --comp-loader-size-width-lg: 32px;
  --comp-loader-size-width-md: 24px;
  --comp-loader-size-width-sm: 16px;
  --comp-loader-size-height-lg: 32px;
  --comp-loader-size-height-md: 24px;
  --comp-loader-size-height-sm: 16px;
  --comp-loader-color: #3b82f6;
  --comp-divider-color-default: #f0f1f2;
  --comp-divider-size-width-sm: 1px;
  --comp-divider-size-height-sm: 1px;
  --comp-textarea-color-foreground-disabled: #a4acb7;
  --comp-textarea-color-foreground-placeholder: #4d6077;
  --comp-textarea-color-foreground-default: #28313d;
  --comp-textarea-color-label-disabled: #a4acb7;
  --comp-textarea-color-label-error: #4d6077;
  --comp-textarea-color-label-focused: #4d6077;
  --comp-textarea-color-label-hover: #4d6077;
  --comp-textarea-color-label-default: #4d6077;
  --comp-textarea-color-border-disabled: #f0f1f2;
  --comp-textarea-color-border-error: #f43f5e;
  --comp-textarea-color-border-focused: #2563eb;
  --comp-textarea-color-border-hover: #a4acb7;
  --comp-textarea-color-border-default: #f0f1f2;
  --comp-textarea-color-background: #ffffff;
  --comp-textarea-border-radius: 8px;
  --comp-textarea-padding-label-leading: 16px;
  --comp-textarea-padding-default: 16px;
  --comp-textarea-height: 120px;
  --comp-list-item-border: [object Object];
  --comp-list-item-dragging: 16px;
  --comp-list-item-color-background-default: #ffffff;
  --comp-list-item-color-foreground-danger: #e11d48;
  --comp-list-item-color-foreground-disabled: #a4acb7;
  --comp-list-item-color-foreground-icon: #4d6077;
  --comp-list-item-color-foreground-text: #28313d;
  --comp-list-item-spacing-gap: 8px;
  --comp-list-item-spacing-icon-touch: 16px;
  --comp-list-item-spacing-sm: 8px;
  --comp-list-item-spacing-default: 16px;
  --comp-list-item-size-icon: 16px;
  --comp-list-item-size-all-default: 48px;
  --comp-list-item-size-height-default: 48px;
  --comp-notification-number-padding-copy-dynamic: 4px;
  --comp-notification-number-color-foreground-danger: #ffffff;
  --comp-notification-number-color-foreground-info: #3b82f6;
  --comp-notification-number-color-foreground-default: #4d6077;
  --comp-notification-number-color-background-danger: #f43f5e;
  --comp-notification-number-color-background-info: #dbeafe;
  --comp-notification-number-color-background-default: #f0f1f2;
  --comp-notification-number-border-radius: 1000px;
  --comp-notification-number-padding-dynamic: 4px;
  --comp-notification-number-size-fixed-width: 20px;
  --comp-notification-number-size-height: 20px;
  --comp-chip-sm-padding-right: 4px;
  --comp-chip-sm-padding-left: 4px;
  --comp-chip-color-foreground-danger: #e11d48;
  --comp-chip-color-foreground-warning: #ca8a04;
  --comp-chip-color-foreground-info: #3b82f6;
  --comp-chip-color-foreground-success: #84cc16;
  --comp-chip-color-foreground-default: #4d6077;
  --comp-chip-color-background-danger: #ffe4e6;
  --comp-chip-color-background-warning: #fef9c3;
  --comp-chip-color-background-info: #dbeafe;
  --comp-chip-color-background-success: #ecfccb;
  --comp-chip-color-background-default: #4d6077;
  --comp-chip-color-border-danger: #f43f5e;
  --comp-chip-color-border-warning: #ca8a04;
  --comp-chip-color-border-info: #3b82f6;
  --comp-chip-color-border-success: #84cc16;
  --comp-chip-color-border-default: #f0f1f2;
  --comp-chip-color-background-default: #ffffff;
  --comp-chip-border-radius: 1000px;
  --comp-chip-border-width: 1px;
  --comp-chip-md-padding-right: 8px;
  --comp-chip-md-padding-left: 8px;
  --comp-chip-size-sm-height: 20px;
  --comp-chip-size-md-height: 24px;
  --comp-text-link-padding-left: 8px;
  --comp-text-link-padding-right: 8px;
  --comp-text-link-color-foreground-disabled: #a4acb7;
  --comp-text-link-color-foreground-focused: #2563eb;
  --comp-text-link-color-foreground-hover: #2563eb;
  --comp-text-link-color-foreground-default: #4d6077;
  --comp-text-link-border-radius: 1000px;
  --comp-text-link-size-height-sm: 24px;
  --comp-text-link-size-height-xs: 16px;
  --comp-dropdown-color-icon-alert-disabled: #a4acb7;
  --comp-dropdown-color-icon-alert-danger: #e11d48;
  --comp-dropdown-color-icon-alert-default: #4d6077;
  --comp-dropdown-color-icon-disabled: #a4acb7;
  --comp-dropdown-color-icon-default: #4d6077;
  --comp-dropdown-color-helper-text-disabled: #a4acb7;
  --comp-dropdown-color-helper-text-error: #e11d48;
  --comp-dropdown-color-helper-text-default: #4d6077;
  --comp-dropdown-color-label-disabled: #a4acb7;
  --comp-dropdown-color-label-error: #4d6077;
  --comp-dropdown-color-label-focused: #4d6077;
  --comp-dropdown-color-label-hover: #4d6077;
  --comp-dropdown-color-label-default: #4d6077;
  --comp-dropdown-color-foreground-disabled: #a4acb7;
  --comp-dropdown-color-foreground-placeholder: #4d6077;
  --comp-dropdown-color-foreground-default: #28313d;
  --comp-dropdown-color-border-disabled: #f0f1f2;
  --comp-dropdown-color-border-error: #f43f5e;
  --comp-dropdown-color-border-focused: #2563eb;
  --comp-dropdown-color-border-hover: #a4acb7;
  --comp-dropdown-color-border-default: #f0f1f2;
  --comp-dropdown-color-background: #ffffff;
  --comp-dropdown-border-radius: 8px;
  --comp-dropdown-spacing-gap: 4px;
  --comp-dropdown-spacing-default: 16px;
  --comp-dropdown-size-icon: 16px;
  --comp-dropdown-size-height: 40px;
  --comp-textfield-color-icon-alert-disabled: #a4acb7;
  --comp-textfield-color-icon-alert-danger: #e11d48;
  --comp-textfield-color-icon-alert-default: #4d6077;
  --comp-textfield-color-icon-disabled: #a4acb7;
  --comp-textfield-color-icon-default: #4d6077;
  --comp-textfield-color-helper-text-disabled: #a4acb7;
  --comp-textfield-color-helper-text-error: #e11d48;
  --comp-textfield-color-helper-text-default: #4d6077;
  --comp-textfield-color-label-disabled: #a4acb7;
  --comp-textfield-color-label-error: #4d6077;
  --comp-textfield-color-label-focused: #4d6077;
  --comp-textfield-color-label-hover: #4d6077;
  --comp-textfield-color-label-default: #4d6077;
  --comp-textfield-color-foreground-disabled: #a4acb7;
  --comp-textfield-color-foreground-placeholder: #4d6077;
  --comp-textfield-color-foreground-default: #28313d;
  --comp-textfield-color-border-disabled: #f0f1f2;
  --comp-textfield-color-border-error: #f43f5e;
  --comp-textfield-color-border-focused: #2563eb;
  --comp-textfield-color-border-hover: #a4acb7;
  --comp-textfield-color-border-default: #f0f1f2;
  --comp-textfield-color-background: #ffffff;
  --comp-textfield-border-radius: 8px;
  --comp-textfield-spacing-helper-text-ver: 0px;
  --comp-textfield-spacing-helper-text-hor: 16px;
  --comp-textfield-spacing-label-leading: 16px;
  --comp-textfield-spacing-gap: 4px;
  --comp-textfield-spacing-default: 16px;
  --comp-textfield-size-icon: 16px;
  --comp-textfield-size-height: 40px;
  --comp-icon-touch-color-foreground-disabled: #a4acb7;
  --comp-icon-touch-color-foreground-success: #84cc16;
  --comp-icon-touch-color-foreground-danger: #e11d48;
  --comp-icon-touch-color-foreground-interactive: #2563eb;
  --comp-icon-touch-color-foreground-default: #4d6077;
  --comp-icon-touch-border-radius: 1000px;
  --comp-icon-touch-size-icon-lg: 20px;
  --comp-icon-touch-size-icon-md: 16px;
  --comp-icon-touch-size-icon-sm: 12px;
  --comp-icon-touch-size-content: 32px;
  --comp-icon-touch-size-bounds: 40px;
  --comp-picker-color-border: #f0f1f2;
  --comp-picker-color-background: #a855f7;
  --comp-picker-color-foreground: #ffffff;
  --comp-picker-border-radius: 8px;
  --comp-picker-size-icon-lg: 32px;
  --comp-picker-size-icon-md: 28px;
  --comp-picker-size-icon-sm: 24px;
  --comp-picker-size-lg: 56px;
  --comp-picker-size-md: 48px;
  --comp-picker-size-sm: 40px;
  --comp-thumbnail-text-padding-sm-right: 2px;
  --comp-thumbnail-text-padding-sm-left: 2px;
  --comp-thumbnail-text-padding-sm-bottom: 12px;
  --comp-thumbnail-text-padding-sm-top: 12px;
  --comp-thumbnail-text-padding-md-right: 4px;
  --comp-thumbnail-text-padding-md-left: 4px;
  --comp-thumbnail-text-padding-md-bottom: 16px;
  --comp-thumbnail-text-padding-md-top: 16px;
  --comp-thumbnail-text-padding-lg-right: 8px;
  --comp-thumbnail-text-padding-lg-left: 8px;
  --comp-thumbnail-text-padding-lg-bottom: 16px;
  --comp-thumbnail-text-padding-lg-top: 16px;
  --comp-thumbnail-color-background: #a855f7;
  --comp-thumbnail-color-foreground: #ffffff;
  --comp-thumbnail-border-radius: 8px;
  --comp-thumbnail-image-size-lg: 56px;
  --comp-thumbnail-image-size-md: 48px;
  --comp-thumbnail-image-size-sm: 40px;
  --comp-avatar-color-background: #4d6077;
  --comp-avatar-color-foreground: #ffffff;
  --comp-avatar-border-radius-circle: 1000px;
  --comp-avatar-border-radius-square: 8px;
  --comp-avatar-size-icon-lg: 24px;
  --comp-avatar-size-icon-md: 24px;
  --comp-avatar-size-icon-sm: 16px;
  --comp-avatar-size-lg: 56px;
  --comp-avatar-size-md: 48px;
  --comp-avatar-size-sm: 40px;
  --comp-icon-button-color-foreground-disabled: #a4acb7;
  --comp-icon-button-color-foreground-danger: #e11d48;
  --comp-icon-button-color-foreground-tertiary: #2563eb;
  --comp-icon-button-color-foreground-secondary: #2563eb;
  --comp-icon-button-color-foreground-primary: #ffffff;
  --comp-icon-button-color-border-disabled: #f0f1f2;
  --comp-icon-button-color-border-secondary: #2563eb;
  --comp-icon-button-color-background-danger-secondary: #fff1f2;
  --comp-icon-button-color-background-danger-primary: #f43f5e;
  --comp-icon-button-color-background-disabled: #f7f8f9;
  --comp-icon-button-color-background-default-secondary: #eff6ff;
  --comp-icon-button-color-background-default-primary: #2563eb;
  --comp-icon-button-border-radius: 8px;
  --comp-icon-button-size-width-lg: 48px;
  --comp-icon-button-size-width-md: 40px;
  --comp-icon-button-size-width-sm: 32px;
  --comp-icon-button-size-icon-xl: 24px;
  --comp-icon-button-size-icon-lg: 16px;
  --comp-icon-button-size-icon-md: 16px;
  --comp-icon-button-size-icon-sm: 12px;
  --comp-icon-button-size-height-lg: 48px;
  --comp-icon-button-size-height-md: 40px;
  --comp-icon-button-size-height-sm: 32px;
  --comp-button-padding-lg-right: 16px;
  --comp-button-padding-lg-left: 16px;
  --comp-button-padding-sm-right: 8px;
  --comp-button-padding-sm-left: 8px;
  --comp-button-padding-md-right: 16px;
  --comp-button-padding-md-left: 16px;
  --comp-button-color-foreground-disabled: #a4acb7;
  --comp-button-color-foreground-danger: #e11d48;
  --comp-button-color-foreground-tertiary: #2563eb;
  --comp-button-color-foreground-secondary: #2563eb;
  --comp-button-color-foreground-primary: #ffffff;
  --comp-button-color-border-disabled: #f0f1f2;
  --comp-button-color-border-secondary: #2563eb;
  --comp-button-color-background-danger-secondary: #fff1f2;
  --comp-button-color-background-danger-primary: #f43f5e;
  --comp-button-color-background-disabled: #f7f8f9;
  --comp-button-color-background-default-secondary: #eff6ff;
  --comp-button-color-background-default-primary: #2563eb;
  --comp-button-border-radius: 8px;
  --comp-button-spacing-gap: 4px;
  --comp-button-spacing-default-lg: 16px;
  --comp-button-spacing-default-md: 16px;
  --comp-button-spacing-default-sm: 8px;
  --comp-button-size-icon-lg: 16px;
  --comp-button-size-icon-md: 16px;
  --comp-button-size-icon-sm: 12px;
  --comp-button-size-height-lg: 48px;
  --comp-button-size-height-md: 40px;
  --comp-button-size-height-sm: 32px;
  --25: 25°;
  --ref-font-line-height-rem-1: var(--sys-size-4);
  --ref-font-size-rem-1: var(--sys-size-4);

  --color-electric-800: #2563eb;
  --color-electric-700: #2563eb;
  --color-pigeon-900: #303744;
  --color-pigeon-800: #404857;
  --color-pigeon-700: #616a7d;
  --color-pigeon-600: #8b93a4;
  --color-pigeon-500: #b4bccc;
  --color-pigeon-400: #cad0dc;
  --color-pigeon-300: #d7dee8;
  --color-pigeon-200: #e8edf4;
  --color-pigeon-100: #f6f8fc;
  --color-pigeon-primary: var(--color-pigeon-800);
  --color-text: var(--color-pigeon-primary);
  --text-font: var(--brand-font-sans-serif);
  --size-base: 15px;
  --size-h1: 1.618em;
  --size-h2: 1.5em;
  --size-h3: 1.25em;
  --size-p: 1em;
  --size-small: 0.875em;
  --size-mini: 0.75em;
}
