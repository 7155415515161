.listItem {
  background: var(--comp-list-item-color-background-default);
  height: var(--comp-list-item-size-height-default);
}

.lead {
  display: flex;
  align-items: center;
  gap: var(--comp-list-item-spacing-gap);
}

.trail {
  display: flex;
}

/* Header */
.listItemHeader {
  display: flex;
  align-items: center;
  height: var(--comp-list-item-size-height-default);
  font-size: var(--sys-typography-label-md-font-size);
  text-decoration: var(--sys-typography-label-md-text-decoration);
  letter-spacing: var(--sys-typography-label-md-letter-spacing);
  line-height: var(--sys-typography-label-md-line-height);
  font-weight: var(--sys-typography-label-md-font-weight);
  padding: 0 var(--sys-size-4);
}

/* Avatar/Checkbox */
.listItemIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

/* Content */
.listItemContent {
  display: flex;
  align-items: center;
  height: var(--comp-list-item-size-height-default);
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  line-height: var(--sys-typography-body-sm-line-height);
  font-weight: var(--sys-typography-body-sm-font-weight);
  color: var(--comp-list-item-color-foreground-text);
  padding: 0 15px;
}

/* Variant */
.listItemVariant {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--sys-size-2);
}

.variant {
  display: flex;
  align-items: center;
  gap: var(--sys-size-2);
}
