.list {
  width: calc(100% - var(--sys-size-10));
  cursor: pointer;
  background: var(--sys-color-surface-00);
  border-radius: var(--sys-size-border-radius-md);
  margin: var(--sys-size-5);
}

.list th:first-child {
  border-top-left-radius: 8px;
}

.list th:last-child {
  border-top-right-radius: 8px;
}

.list th {
  padding: 0;
  position: sticky;
  white-space: nowrap;
  top: 0;
  z-index: 1;
  background: var(--sys-color-surface-00);
}

.list tr {
  outline: none;
}

.list td {
  height: var(--comp-list-item-size-height-default);
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
  max-width: 250px;
  text-overflow: ellipsis;
  /* overflow: hidden; */
  white-space: nowrap;
}

.list tbody > tr > td:last-child {
  margin-right: 10em;
}

.checkBox,
.color,
.columnSettings,
.more {
  width: 48px;
  min-width: 48px !important;
  max-width: 48px;
}

.customFieldDate {
  overflow: visible !important;
}

.subLine {
  background: var(--sys-color-surface-01);
}

/* Stat last line */
.bold {
  font-weight: bold;
}

/* Icon */
.listItemIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: var(--sys-size-2);
}

/* Mobile */
@media screen and (max-width: 767px) {
  .list th {
    min-width: inherit;
  }
}
