.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.containerColorList {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--sys-color-surface-00);
  border: 1px solid var(--sys-color-border-default);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px #c2c7ce;
  border-radius: 8px;
  padding: 16px;
  width: 282px;
  top: 110%;
  gap: 8px;
  z-index: 150;
}

.containerColorList2 > *:not(:first-child) {
  margin-top: 8px;
}

.containerColorList:focus {
  outline: none;
}

.colorClose {
  position: absolute;
  top: 0;
  right: 0;
}

.label {
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  letter-spacing: var(--sys-typography-label-sm-letter-spacing);
  line-height: var(--sys-typography-label-sm-line-height);
  font-weight: var(--sys-typography-label-sm-font-weight);
}

.colorList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px;
  gap: 8px;
}

.containerIconText {
  display: flex;
  justify-content: space-between;
}

.iconText {
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  line-height: var(--sys-typography-label-xs-line-height);
  font-weight: var(--sys-typography-label-xs-font-weight);
  color: var(--sys-color-content-secondary);
}
