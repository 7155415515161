.container {
  position: relative;
  height: var(--sys-size-10);
  border-radius: var(--sys-size-border-radius-md);
  padding: 0 var(--sys-size-4);
}

.container:hover {
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-hover);
}

.container:focus-within {
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-focused);
}

.listItems {
  position: fixed;
  cursor: pointer;
  max-height: 50vh;
  background: var(--sys-color-surface-00);
  box-shadow: 0px 2px 4px #c2c7ce;
  /* box-shadow: var(--sys-shadow-box-md-x) var(--sys-shadow-box-md-y) var(--sys-shadow-box-md-blur)
  var(----sys-shadow-box-md-color); */
  border-radius: 8px;
  padding: 0.5em 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 101;
}

/* Create */

.item {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
  height: var(--comp-list-item-size-height-sm);
  padding: var(--sys-size-4);
}

.item:hover,
.itemCreate:hover {
  background: var(--st-layer-color-default-hover);
}

.item:focus-within,
.itemCreate:focus-within {
  background: var(--sys-layer-color-focused);
}

.itemCreate {
  composes: item;
  justify-content: space-between;
}

.createValue {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--sys-color-content-primary);
}

.createButton {
  display: flex;
  align-items: center;
  color: var(--sys-color-content-interactive);
}
