/* Tag */

.containerTags {
  display: flex;
  align-items: center;
  height: var(--comp-chip-size-md-height);
  padding: 0 var(--comp-chip-md-padding-right) 0 var(--comp-chip-md-padding-left);
  background: var(--comp-chip-color-background-default);
  border-radius: var(--comp-chip-border-radius);
  border: var(--comp-chip-border-width) solid var(--comp-chip-color-border-default);
  font-weight: var(--sys-typography-label-sm-font-weight);
  font-size: var(--sys-typography-label-sm-font-size);
  line-height: var(--sys-typography-label-sm-line-height);
  color: var(--comp-chip-tag-color-foreground-default);
  gap: var(--comp-chip-padding-gap);
  margin-right: 4px;
}

.tagPrice {
  color: var(--sys-color-content-info);
}
