button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

.button {
  border-radius: var(--comp-button-border-radius);
}
