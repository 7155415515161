.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background: var(--sys-color-surface-01); */
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: var(--sys-color-content-primary);
  padding: 1em;
  height: 70px;
  height: 5%;
  /* position: fixed; */
  width: 400px;
  z-index: 2;
  top: 0;
  border-bottom: 1px solid var(--comp-divider-color-default);
}

.headerSideButton {
  cursor: pointer;
}

.headerTitle {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--sys-typography-title-md-font-size);
  text-decoration: var(--sys-typography-title-md-text-decoration);
  letter-spacing: var(--sys-typography-title-md-letter-spacing);
  line-height: var(--sys-typography-title-md-line-height);
  font-weight: 600;
  cursor: default;
}

/* Bulk Actions */

.container {
  height: 100vh;
  background: var(--sys-color-surface-00);
}

.description {
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  line-height: var(--sys-typography-label-xs-line-height);
  color: var(--sys-color-content-secondary);
  padding-top: var(--comp-toaster-padding-top);
}

/* Form */

.form::-webkit-scrollbar {
  display: none;
}

.containerForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overflow {
  /* To experiment */
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.form {
  padding: 0.5rem 1.3rem;
}

.form > div {
  margin: 1rem 0;
}

.oneLine {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.oneLine > * {
  width: 50% !important;
}

.removeButton {
  position: absolute;
  text-align: center;
  cursor: pointer;
  color: var(--sys-color-content-danger);
  background: var(--sys-color-surface-00);
  width: calc(400px - 2em);
  bottom: 5rem;
  outline: none;
}

.submitButton {
  position: absolute;
  bottom: 0;
  padding: 1.5em 1em;
  background: var(--sys-color-surface-00);
}

.fusionButton {
  background: var(--sys-color-surface-00);
}

/* Phone */

.buttonClass {
  background: none !important;
  border: none !important;
}

.buttonClass > div:first-child {
  border: none !important;
  height: calc(var(--comp-textfield-size-height) / 1.8) !important;
  margin-top: calc(var(--sys-size-2)) !important;
  margin-left: 8px !important;
}

.inputClass {
  width: 100% !important;
  height: var(--sys-size-10) !important;
  border-radius: var(--sys-size-border-radius-md) !important;
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-default) !important;
  gap: var(--sys-size-1) !important;
  font-size: var(--sys-typography-body-sm-font-size) !important;
  text-decoration: var(--sys-typography-body-sm-text-decoration) !important;
  letter-spacing: var(--sys-typography-body-sm-letter-spacing) !important;
  font-weight: var(--sys-typography-body-sm-font-weight) !important;
  line-height: var(--sys-typography-body-sm-line-height) !important;
}

.inputClass:hover {
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-hover) !important;
}

.inputClass:focus-within {
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-focused) !important;
}

.inputClassError {
  composes: inputClass;
  border: 1px solid var(--comp-textfield-color-helper-text-error) !important;
}

.inputClassError:hover {
  border: 1px solid var(--comp-textfield-color-helper-text-error) !important;
}

.inputClassError:focus-within {
  border: 1px solid var(--comp-textfield-color-helper-text-error) !important;
}

/* Link to Requester */

.linkToRequester {
  display: flex;
  justify-content: center;
  color: var(--sys-color-content-interactive);
  cursor: pointer;
  text-align: center;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

.fusionHeader {
  padding-top: 1em;
  overflow-y: auto;
  height: 95%;
  position: relative;
}

.iconContainer {
  position: relative;
  height: var(--comp-thumbnail-image-size-lg);
  padding: 1rem;
}

.iconWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 1rem;
  top: 0;
  left: 0;
  transform: translate(-10%, -25%);
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  position: absolute;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descriptionFusion {
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  letter-spacing: var(--sys-typography-label-sm-letter-spacing);
  line-height: var(--sys-typography-label-sm-line-height);
  color: var(--sys-color-content-secondary);
  text-align: center;
}

.fusionContentWrapper {
  max-height: calc(100% - 72px);
  overflow-y: auto;
  scrollbar-width: none;
}

.fusionContentWrapper::-webkit-scrollbar {
  display: none;
}

.buttonFusionContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5%;
  display: flex;
  justify-content: space-evenly;
  gap: 24px;
  align-items: center;
  padding: 12px 24px;
  border-top: 1px solid #f0f1f2;
  height: 72px;
}

.titleHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.titleHeader hr {
  flex-grow: 1;
  height: 1px;
  background-color: #f0f1f2;
  border: none;
}

.titleHeaderText {
  flex-shrink: 0;
  margin: 0 16px;
  color: var(--ref-color-slate-600);
  font-size: var(--sys-typography-label-sm-font-size);
  line-height: var(--sys-typography-label-sm-line-height);
  font-weight: var(--sys-typography-label-sm-font-weight);
  font-family: var(--sys-typography-label-sm-font-family);
}

.fieldContainer {
  margin: 24px 32px;
}

.valueContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.valueContainerNoHover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 8px;
}

.valueContainer:hover {
  background: var(--st-layer-color-default-focused);
}

.valueText {
  font-size: var(--sys-typography-label-sm-font-size);
  line-height: var(--sys-typography-label-sm-line-height);
  font-weight: var(--sys-typography-label-sm-font-weight);
  font-family: var(--sys-typography-label-sm-font-family);
  max-width: 270px;
}

.valueRadio {
  -webkit-appearance: auto;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.helperText {
  padding-left: var(--sys-size-4);
}

.helperText {
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  font-weight: var(--sys-typography-label-xs-font-weight);
  line-height: var(--sys-typography-label-xs-line-height);
  margin-top: 0px !important;
  margin-bottom: -8px;
}
