.list {
  max-height: 50vh;
  background: var(--sys-color-surface-00);
  box-shadow: 0px 2px 4px #c2c7ce;
  /* box-shadow: var(--sys-shadow-box-md-x) var(--sys-shadow-box-md-y) var(--sys-shadow-box-md-blur)
  var(----sys-shadow-box-md-color); */
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  padding: 0.5em 0;
  border-radius: 8px;
  /* max-width: 300px; */
  white-space: nowrap;
  z-index: 3;
  position: fixed;
  width: fit-content;
}

.menuList {
  composes: list;
  position: absolute;
  left: calc(248px + 217px + 1em);
  bottom: 7em;
  width: 200px;
}

.list > *:not(:first-child) {
  margin-top: 0.25em;
}
