.container {
  background: var(--sys-color-surface-00);
  overflow: scroll;
  max-height: 100vh;
}

.selector {
  display: flex;
  align-items: center;
}

/* Mobile */
@media screen and (max-width: 767px) {
  .selector {
    flex-direction: column;
  }

  .selector > * {
    width: 100%;
    margin-left: 0;
  }
}
