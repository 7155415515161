.validate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  border-top: 1px solid var(--comp-divider-color-default);
  cursor: pointer;
}

.textValidate {
  font-size: var(--sys-typography-label-md-font-size);
  text-decoration: var(--sys-typography-label-md-text-decoration);
  letter-spacing: var(--sys-typography-label-md-letter-spacing);
  font-weight: var(--sys-typography-label-md-font-weight);
  line-height: var(--sys-typography-label-md-line-height);
}

.validButton {
  display: flex;
  justify-content: center;
  padding-top: 0.5em;
}
