/* List */

.title {
  color: var(--sys-color-content-secondary);
  font-weight: 500;
  font-size: var(--sys-typography-label-lg-font-size);
  text-decoration: var(--sys-typography-label-lg-text-decoration);
  letter-spacing: var(--sys-typography-label-lg-letter-spacing);
  line-height: var(--sys-typography-label-lg-line-height);
  color: var(--sys-color-content-secondary);
}

.listContainer {
  border: 1px solid var(--sys-color-border-default);
  border-radius: 8px;
  padding: var(--sys-size-2) 0;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

.rowContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}

.rowContainer:not(:last-child) {
  border-bottom: 1px solid var(--sys-color-border-default);
}

.leftPart {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: var(--sys-size-3);
}

.leftPart > * {
  margin-right: 1em;
}

.iconHoverLeft {
  background: var(--sys-color-content-secondary);
  border-radius: 4px;
  padding: 10px 20px;
  position: absolute;
  bottom: 120%;
  font-weight: 500;
  white-space: nowrap;
}

.arrowIconLeft {
  width: 8px;
  height: 8px;
  position: absolute;
  background: var(--sys-color-content-secondary);
  transform: rotate(-45deg);
  top: 92%;
  left: 14px;
}

.rightPart {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: var(--sys-size-3);
}

/* Drawer */
.containerDrawer {
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-height: 60px;
}

/* Title */

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 60px;
}

.ellipsis {
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text {
  color: var(--sys-color-content-secondary);
  margin: 5px 0;
  overflow-wrap: break-word;
}

.orangeText {
  color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  border-radius: 2px;
  padding: 1px;
}

/* Payment Methods */
.paymentMethods {
  display: flex;
}

/* Type */
.type {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/*-------------------*/

.containerLine {
  width: 100%;
  padding: 5px;
}

.containerNameQuantity {
  display: flex;
  width: 70%;
}

.name {
  font-weight: 500;
  overflow-wrap: break-word;
}

.containerName {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
}

.discount {
  display: flex;
  justify-content: space-between;
}

.discountName {
  composes: discount;
  flex-direction: column;
  max-width: 70%;
}

.infoName {
  margin-top: 5px;
  max-width: 90%;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

.infoDecli {
  composes: text;
  font-weight: 600;
  max-width: 90%;
}
