:root {
  --ff-primary-color: var(--sys-color-content-interactive) !important;
  --ff-secondary-color: #616a7d !important;
  --ff-text-color: #090b2b !important;
  --ff-dialog-border-radius: 4px !important;
  --ff-border-radius: 5px !important;
  --ff-bg-fade: rgba(0, 0, 0, 0.2) !important;
}

/* The default mount element */
.flatfile_iFrameContainer {
  padding: 0px !important;
  width: 100% !important;
  height: 100% !important;
  /* border-radius: 20px !important; */
}

/* A div around the iframe that contains Flatfile */
/* .flatfile_iframe-wrapper {
} */

/* The actual iframe that contains Flatfile */
/* #flatfile_iframe {
} */

/* Begin style overrides for when Flatfile is displayed as a modal */

/* This class gets appended to the flatfile_iframe-wrapper div */
.flatfile_displayAsModal {
  padding: 20px !important;
  width: 100% !important;
  height: 100% !important;
}

.flatfile_iframe-wrapper.flatfile_displayAsModal {
  background: var(--ff-bg-fade) !important;
}

.flatfile-close-button {
  margin: 0px !important;
  margin-top: 0px !important;
  background-color: #5d8bf0 !important;
  box-shadow: none !important;
}

.flatfile_iframe-wrapper [class*="flatfile_iFrameContainer"] {
  padding-right: 30px !important;
}

/* The close button in top right to close modal */
/* .flatfile_displayAsModal .flatfile_close-button {
  padding: 0px !important;
} */

/* The icon for the close button in top right to close modal */
/* .flatfile_displayAsModal .flatfile_close-button svg { */
  /* fill: #000000 !important; */
/* } */

/* The actual iframe that contains Flatfile */
.flatfile_displayAsModal #flatfile_iframe {
  border-radius: var(--ff-border-radius);
}

/* Begin style overrides for when you cancel out of the Flatfile modal */

/* The outer container of the modal that opens when you cancel out of Flatfile */
.flatfile_outer-shell {
  background-color: var(--ff-bg-fade) !important;
  border-radius: var(--ff-border-radius) !important;
}

/* The inner container of the modal that opens when you cancel out of Flatfile */
/* .flatfile_inner-shell {
} */

/* The white box inside the modal that opens when you cancel out of Flatfile */
.flatfile_modal {
  border-radius: var(--ff-dialog-border-radius) !important;
}

/* The container for the buttons you see in the close modal */
/* .flatfile_button-group {
} */

/* Style the buttons you see in the close modal */
/* .flatfile_button {
} */

/* The "yes, cancel" button you see in the close modal */
.flatfile_primary {
  border: 1px solid var(--ff-primary-color) !important;
  background-color: var(--ff-primary-color) !important;
  color: #fff;
}

/* The "no, stay" button you see in the close modal */
.flatfile_secondary {
  color: var(--ff-secondary-color) !important;
}

/* The heading text you see in the close modal */
.flatfile_modal-heading {
  color: var(--ff-text-color) !important;
}

/* The description text you see in the close modal */
.flatfile_modal-text {
  color: var(--ff-secondary-color) !important;
}

/* End style overrides for when you cancel out of the Flatfile modal */

/* End style overrides for when Flatfile is displayed as a modal */

/* The container of the error component */
/* .ff_error_container {
}*/

/* The heading text you see in the error component */
/* .ff_error_heading {
}*/

/* The description text you see in the error component */
/* .ff_error_text {
}*/
