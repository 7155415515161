.form {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 200px;
  margin-top: 8rem;
}

.formMovement {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 6rem;
}

.shopName {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 0.3rem;
}

.name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 0.5rem;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.modalCross {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  cursor: pointer;
}

.modalSuccess {
  font-weight: 700;
  position: absolute;
  text-align: center;
  top: 42%;
}

.modalTextArea {
  position: absolute;
  top: 55%;
  width: 90%;
  text-align: center;
}

.modalButton {
  margin-top: 4rem;
  text-align: center;
  width: 100%;
}
