.progressBar {
  position: relative;
  background-color: var(--comp-progress-bar-color-base);
  border-radius: var(--comp-progress-bar-border-radius);
  width: 122px;
}

.progressBarFill {
  position: absolute;
  background-color: var(--comp-progress-bar-color-fill);
  border-radius: var(--comp-progress-bar-border-radius);
}
