.textLink {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: var(--comp-text-link-border-radius);
}

.textLink:hover {
  color: var(--st-layer-color-default-hover);
}
