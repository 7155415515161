.chip {
  gap: var(--comp-chip-padding-gap);
  font-weight: var(--sys-typography-label-sm-font-weight);
  font-size: var(--sys-typography-label-sm-font-size);
  line-height: var(--sys-typography-label-sm-line-height);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  width: fit-content;
  cursor: default;
  display: flex;
  align-items: center;
}

.chip_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.chip_container p {
  font-weight: var(--sys-typography-label-sm-font-weight);
  font-size: var(--sys-typography-label-xs-font-size);
  line-height: var(--sys-typography-label-sm-line-height);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  padding: 0px 4px;
}
