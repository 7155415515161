.container {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  height: var(--sys-size-10);
  border-radius: var(--sys-size-border-radius-md);
  gap: var(--sys-size-1);
}

.label {
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

input {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

input::placeholder {
  color: var(--sys-color-content-secondary);
}

.label {
  position: absolute;
  background: var(--sys-color-surface-00);
  transition: all 0.3s ease;
  pointer-events: none;
  padding: var(--sys-size-1);
}

.helperText {
  padding-left: var(--sys-size-4);
}

.helperText {
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  font-weight: var(--sys-typography-label-xs-font-weight);
  line-height: var(--sys-typography-label-xs-line-height);
}

input:focus ~ label,
input:not([value='']) ~ label,
input:placeholder-shown ~ label {
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  font-weight: var(--sys-typography-label-xs-font-weight);
  line-height: var(--sys-typography-label-xs-line-height);
  transform: translateY(-20px);
}

::-webkit-calendar-picker-indicator {
  display: none;
}
