.container {
  display: flex;
  position: relative;
  align-items: center;
}

.text {
  margin-top: 8px;
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  line-height: var(--sys-typography-label-xs-line-height);
  font-weight: var(--sys-typography-label-xs-font-weight);
  color: var(--sys-color-content-secondary);
}
