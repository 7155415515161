.container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  bottom: 0%;
  transform: translate(50%, -10%);
  width: 50vw;
  height: 6vh;
  pointer-events: none;
}

.toaster {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 5;
  bottom: 27px;
  color: var(--comp-toaster-color-foreground-text);
  cursor: default;
  gap: var(--comp-toaster-padding-gap);
  padding: var(--comp-toaster-padding-top) var(--comp-toaster-padding-right) var(--comp-toaster-padding-bottom)
    var(--comp-toaster-padding-left);
  background: var(--comp-toaster-color-background);
  border-radius: var(--comp-toaster-border-radius);
  max-width: fit-content;
  box-shadow: var(--comp-toaster-shadow-x) var(--comp-toaster-shadow-y) var(--comp-toaster-shadow-blur)
    var(--comp-toaster-shadow-spread) var(--comp-toaster-shadow-color);
  animation: enterIn linear forwards, enterOut linear forwards;
  animation-duration: 0.5s, 1s;
  animation-delay: 0s, 3s;
}

@keyframes enterIn {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 27px;
    opacity: 1;
  }
}

@keyframes enterOut {
  from {
    bottom: 27px;
    opacity: 1;
  }
  to {
    opacity: 0;
    bottom: -100px;
  }
}

.text {
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  letter-spacing: var(--sys-typography-label-sm-letter-spacing);
  font-weight: var(--sys-typography-label-sm-font-weight);
  line-height: var(--sys-typography-label-sm-line-height);
}

.cross {
  position: absolute;
  right: var(--comp-toaster-padding-right);
}

.cross > * {
  width: var(--comp-toast-size-icon);
  height: var(--comp-toast-size-icon);
}

.hidden {
  display: none;
}
