.MuiPickersCalendarHeader-labelContainer {
  text-decoration: var(--sys-typography-body-sm-text-decoration) !important;
  letter-spacing: var(--sys-typography-body-sm-letter-spacing) !important;
  font-weight: var(--sys-typography-body-sm-font-weight) !important;
  line-height: var(--sys-typography-body-sm-line-height) !important;
  font-family: 'Poppins', sans-serif !important;
}

.MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
  display: none !important;
}

.switchViewButton {
  display: none !important;
}

.MuiButtonBase-root:hover,
.MuiPickersDay-root:hover {
  color: var(--comp-button-color-background-default-primary) !important;
  /* background-color: var(--st-layer-color-default-hover) !important; */
  border-radius: 50% !important;
}

.MuiButtonBase-root .MuiPickersDay-root .MuiDateRangePickerDay-day .Mui-selected {
  background-color: transparent !important;
}

.Mui-selected {
  background-color: var(--comp-button-color-background-default-primary) !important;
  color: #fff !important;
}

.Mui-selected:hover {
  color: white !important;
  background-color: var(--comp-button-color-background-default-primary) !important;
}

.MuiPickersLayout-root {
  text-decoration: var(--sys-typography-body-sm-text-decoration) !important;
  letter-spacing: var(--sys-typography-body-sm-letter-spacing) !important;
  font-weight: var(--sys-typography-body-sm-font-weight) !important;
  line-height: var(--sys-typography-body-sm-line-height) !important;
  font-family: 'Poppins', sans- serif !important;
}

.MuiButtonBase-root:not(.Mui-selected),
.MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid transparent !important;
}

.MuiInputBase-input::placeholder {
  display: none !important;
  color: transparent !important;
}

.dateRange__shortcut {
  display: flex !important;
  /* justify-content: space-evenly !important; */
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 12px !important;
  padding: 8px 16px !important;
}

.dateRange__shortcut__item {
  width: 100%;
  cursor: default;
  padding: var(--comp-tab-list-item-padding-sm);
  border-radius: var(--comp-tab-list-item-border-radius-md);
  background-color: var(--st-layer-color-default-focused);
  color: var(--sys-color-content-interactive);
  text-decoration: var(--sys-typography-label-md-text-decoration);
  letter-spacing: var(--sys-typography-label-md-letter-spacing);
  font-size: var(--sys-typography-label-md-font-size);
  line-height: var(--sys-typography-label-md-line-height);
  font-weight: var(--sys-typography-label-md-font-weight);
  font-family: var(--sys-typography-label-md-font-family);
}

.dateRange__shortcut__item:hover {
  background-color: #2564eb2f;
}

.dateRange__shortcut__item__disabled {
  width: 100%;
  cursor: default;
  padding: var(--comp-tab-list-item-padding-sm);
  border-radius: var(--comp-tab-list-item-border-radius-md);
  color: var(--sys-color-content-interactive);
  text-decoration: var(--sys-typography-label-md-text-decoration);
  letter-spacing: var(--sys-typography-label-md-letter-spacing);
  font-size: var(--sys-typography-label-md-font-size);
  line-height: var(--sys-typography-label-md-line-height);
  font-weight: var(--sys-typography-label-md-font-weight);
  font-family: var(--sys-typography-label-md-font-family);
}

/* .MuiDateRangePickerDay-rangeIntervalPreview {
  color: var(--comp-button-color-background-default-primary) !important;
} */

.MuiDateRangePickerDay-day {
  font-family: 'Poppins' !important;
}

.MuiPickersDay-root .MuiPickersDay-hiddenDaySpacingFiller {
  background-color: red !important;
}

/* ici c'est le premier jour séléctionné */

.MuiDateRangePickerDay-root
  .MuiDateRangePickerDay-rangeIntervalDayHighlight
  .MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.MuiDateRangePickerDay-root .MuiDateRangePickerDay-dayInsideRangeInterval {
  /* background-color: var(--st-layer-color-default-hover) !important; */
  /* background-color: blue !important; */
  /* border-radius: 0 !important; */
  color: var(--comp-button-color-background-default-primary) !important;
}
.MuiButtonBase-root .MuiPickersDay-root .MuiPickersDay-today {
}

.MuiDateRangePickerDay-root .MuiDateRangePickerDay-dayInsideRangeInterval:hover {
  background-color: var(--st-layer-color-default-hover) !important;
  /* background-color: blue !important; */
  /* border-radius: 0 !important; */
}
.MuiDateRangePickerDay-root .MuiDateRangePickerDay-notSelectedDate {
  border-radius: 0 !important;
}

.MuiDateRangePickerDay-root,
.MuiDateRangePickerDay-root {
  /*! ICI pour la selection en border radius */
  /* border-radius: 0% !important; */
}

.MuiDateRangePickerDay-root .Mui-selected,
.MuiDateRangePickerDay-root .Mui-selected {
  border-radius: 50% !important;
  color: #fff !important;
}

.MuiPickersDay-today:not(.Mui-selected) {
  color: var(--comp-button-color-background-default-primary) !important;
}

.Mui-selected .MuiPickersDay-today {
  color: #fff !important;
}

.MuiDateRangePickerDay-root:hover,
.MuiDateRangePickerDay-root:focus {
  background-color: #2564eb2f !important;
  border-radius: 50% !important;
  color: var(--comp-button-color-background-default-primary) !important;
  /*//! ICI */
}

.MuiDateRangePickerDay-root:hover .Mui-selected,
.MuiDateRangePickerDay-root:focus .Mui-selected {
  background-color: var(--comp-button-color-background-default-primary) !important;
  color: white !important;
  /* border-radius: 0 !important; */
}

/* .MuiDateRangePickerDay-root .Mui-selected:hover,
.MuiDateRangePickerDay-root .Mui-selected:hover {
  color: red !important;
} */

.MuiPickersLayout-root {
  display: block !important;
}

.MuiOutlinedInput-input {
  padding: 11px !important;
}
