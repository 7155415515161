.topBar {
  background: var(--sys-color-surface-00);
  border-bottom: 1px solid var(--comp-divider-color-default);
}

.path {
  display: flex;
  align-items: center;
  font-size: var(--sys-typography-label-lg-font-size);
  text-decoration: var(--sys-typography-label-lg-text-decoration);
  letter-spacing: var(--sys-typography-label-lg-letter-spacing);
  line-height: var(--sys-typography-label-lg-line-height);
  font-weight: 600;
  gap: var(--sys-size-1);
  color: var(--sys-color-content-primary);
  cursor: default;
}

.containerPart {
  display: flex;
  padding: 0 var(--sys-size-4);
  align-items: center;
  justify-content: space-between;
  height: 72px;
}

.part {
  display: flex;
  align-items: center;
  flex: 1;
}

.containerPart > *:last-child {
  flex-direction: row-reverse;
}

/* Mobile */
@media screen and (max-width: 767px) {
  .topBar {
    justify-content: space-between;
  }

  .part {
    flex: auto;
  }
}
