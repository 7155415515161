/* Split View */
.container {
  display: flex;
}

.part {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100vh;
}

.grid {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  background: #fafbfd;
}

/* Form */
.containerForm {
  position: relative;
  display: flex;
  flex-direction: column;
}

.middleArea > div:not(:last-child) {
  margin-bottom: 32px;
}

.bottomArea > *:not(:first-child) {
  margin-top: var(--sys-size-2);
}

.form > div:not(:last-child) {
  margin-bottom: 16px;
}

.gcs {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--sys-color-content-secondary);
}

.gcs > *:not(:first-child) {
  margin-left: var(--sys-size-2);
}

.forgotPasswordButtons {
  display: flex;
}

.forgotPasswordButtons > * {
  width: 90px;
  margin-right: 16px;
}

.previous {
  position: absolute;
  color: var(--sys-color-content-interactive);
  display: flex;
  align-items: center;
  top: 24px;
  left: 21px;
  z-index: 1;
  cursor: pointer;
}

.previous > div {
  margin-left: 14px;
  font-weight: 600;
  font-size: 14.4px;
  line-height: 24px;
}

.previousStep {
  cursor: default;
  font-size: var(--sys-typography-title-sm-font-size);
  text-decoration: var(--sys-typography-title-sm-text-decoration);
  letter-spacing: var(--sys-typography-title-sm-letter-spacing);
  line-height: var(--sys-typography-title-sm-line-height);
  font-weight: var(--sys-typography-title-sm-font-weight);
  color: var(--sys-color-content-secondary);
}

.steps {
  margin-top: 5em;
}

.steps > div {
  margin-top: var(--sys-size-2);
}

.containerPostSignUp {
  position: relative;
}

.close {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
}

/* Desktop */
@media screen and (min-width: 1024px) {
  .containerForm > div:not(:first-child):not(:last-child) {
    margin: 4em 0;
  }
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .containerForm > div:not(:first-child):not(:last-child) {
    margin: 2em 0;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .grid {
    display: none;
  }
  .part {
    width: 100%;
  }

  .containerForm {
    margin: 1.5em 0;
  }

  .containerForm > div:not(:first-child):not(:last-child) {
    margin: 32px 0;
  }

  .submitButton {
    position: absolute;
    bottom: 0px;
  }

  .forgotPasswordButtons {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .forgotPasswordButtons > * {
    margin-top: 16px;
    width: 100%;
  }

  .forgotPasswordButtons > div {
    width: 100%;
    margin-top: 16px;
  }
}

.title {
  font-size: var(--sys-typography-title-lg-font-size);
  text-decoration: var(--sys-typography-title-lg-text-decoration);
  letter-spacing: var(--sys-typography-title-lg-letter-spacing);
  line-height: var(--sys-typography-title-lg-line-height);
  font-weight: var(--sys-typography-title-lg-font-weight);
  color: var(--sys-color-content-primary);
}

.subTitle {
  font-size: var(--sys-typography-title-md-font-size);
  text-decoration: var(--sys-typography-title-md-text-decoration);
  letter-spacing: var(--sys-typography-title-md-letter-spacing);
  line-height: var(--sys-typography-title-md-line-height);
  font-weight: var(--sys-typography-title-md-font-weight);
  color: var(--sys-color-content-secondary);
}

.text {
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  line-height: var(--sys-typography-body-sm-line-height);
  font-weight: var(--sys-typography-body-sm-font-weight);
}

.action {
  composes: text;
  text-decoration-line: underline;
  cursor: pointer;
}
