.containerChildren {
  position: relative;
  border-radius: 8px;
  background: var(--sys-color-surface-00);
  padding: 2rem;
  z-index: 6;
  min-width: 35em;
  max-width: 35em;
  max-height: 90vh;
  overflow-y: auto;
}

.containerChildrenCustom {
  position: relative;
  border-radius: 16px;
  background: var(--sys-color-surface-00);
  z-index: 6;
  min-width: 35em;
  max-width: 35em;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0px 2px 12px 4px #00000040;
}

.grid {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.hidden {
  display: none;
}

.background {
  transition: background-color 0.5s ease;
  background: #011635;
  opacity: 0.3;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;
}

.modal {
  white-space: pre-wrap;
}

.modal > *:not(:first-child) {
  margin-top: 1.5em;
}

.modalLoader {
  background: var(--sys-color-surface-00);
  padding: 2rem;
  z-index: 6;
  min-width: 35em;
  max-width: 35em;
  max-height: 90vh;
  min-height: 19rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--sys-color-content-interactive);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modalTitle {
  cursor: default;
  font-size: var(--sys-typography-title-md-font-size);
  text-decoration: var(--sys-typography-title-md-text-decoration);
  letter-spacing: var(--sys-typography-title-md-letter-spacing);
  line-height: var(--sys-typography-title-md-line-height);
  font-weight: 600;
  color: var(--sys-color-content-primary);
}

.modalTitleIcon {
  width: 24px;
  height: 24px;
}

.modalTitleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid rgba(135, 135, 135, 0.1);
}

.modalTitleCustom {
  cursor: default;
  font-size: var(--sys-typography-title-lg-font-size);
  text-decoration: var(--sys-typography-title-md-text-decoration);
  letter-spacing: var(--sys-typography-title-md-letter-spacing);
  line-height: var(--sys-typography-title-md-line-height);
  font-weight: 600;
  color: var(--sys-color-content-primary);
  padding: 2rem 2rem 1rem 2rem;
}

.modalDangerTitle {
  composes: modalTitle;
  color: var(--sys-color-content-danger);
}

.modalButtons {
  display: flex;
  gap: 8px;
}

.modalButtonsCustom {
  display: flex;
  gap: 24px;
  padding: 0rem 2rem 1rem 2rem;
}

.modalContent > *:not(:first-child) {
  margin-top: 16px;
}

.modalContentCustom {
  padding: 0rem 2rem 1rem 2rem;
}

.modalContentCustomFlex {
  padding: 0rem 2rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.modalInfo {
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  line-height: var(--sys-typography-body-sm-line-height);
  font-weight: var(--sys-typography-body-sm-font-weight);
  color: var(--sys-color-content-secondary);
}

.modalInfoExport {
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  line-height: var(--sys-typography-body-sm-line-height);
  font-weight: 400;
  color: var(--sys-color-content-secondary);
}

.modalImportantInfo {
  composes: modalInfoExport;
  color: #1e293b;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.modalVeryImportantInfo {
  composes: modalInfo;
  color: var(--sys-color-content-primary);
  font-weight: 800;
  font-size: var(--sys-typography-body-md-font-size);
}

.modalButtonsSlot {
  display: flex;
  gap: 24px;
  padding: 0rem 2rem 1rem 2rem;
}

.modalImportantInfoExport {
  composes: modalInfoExport;
  color: #000;
  font-weight: 600;
}

.modalFlex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modalHelperText {
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  line-height: var(--sys-typography-label-xs-line-height);
  font-weight: var(--sys-typography-label-xs-font-weight);
  color: var(--sys-color-content-secondary);
}

.modalTitleCustom {
  cursor: default;
  font-size: 20px;
  text-decoration: var(--sys-typography-title-md-text-decoration);
  letter-spacing: var(--sys-typography-title-md-letter-spacing);
  line-height: var(--sys-typography-title-lg-line-height);
  font-weight: 600;
  color: #1e293b;
  padding: 2rem 2rem 1rem 2rem;
}

.modalContentCustom {
  padding: 0rem 2rem 1rem 2rem;
}

.modalSubTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modalImagePickerContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 1.5em !important;
}

.modalTitleExport {
  cursor: default;
  font-size: var(--sys-typography-title-sm-font-size);
  text-decoration: var(--sys-typography-title-md-text-decoration);
  letter-spacing: var(--sys-typography-title-md-letter-spacing);
  line-height: var(--sys-typography-title-md-line-height);
  font-weight: 600;
  color: #000;
}

.modalTitleContainer {
  /* padding: 24px; */
  border-bottom: 1px solid rgba(135, 135, 135, 0.1);
}

.modalSubTitle {
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  letter-spacing: var(--sys-typography-label-sm-letter-spacing);
  line-height: var(--sys-typography-label-sm-line-height);
  font-weight: 700;
  color: var(--sys-color-content-primary);
}

.modalContentInventory {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.modalButtonsCustom {
  display: flex;
  gap: 24px;
  padding: 0rem 2rem 1rem 2rem;
}

.modalContentCustom {
  padding: 0rem 2rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
}

.modalError {
  font-size: var(--sys-typography-body-md-font-size);
  text-decoration: var(--sys-typography-body-md-text-decoration);
  letter-spacing: var(--sys-typography-body-md-letter-spacing);
  line-height: var(--sys-typography-body-md-line-height);
  font-weight: var(--sys-typography-body-md-font-weight);
  color: var(--sys-color-content-primary);
}

.importContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.containerLoader {
  background-color: var(--ref-color-pure-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.fusionContentWrapper {
  display: flex;
  justify-content: space-between;
}

.loaderText {
  font-size: var(--sys-typography-label-lg-font-size);
  line-height: var(--sys-typography-label-lg-line-height);
  font-weight: var(--sys-typography-label-lg-font-weight);
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--sys-color-content-interactive);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
