.containerCreate {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 80px;
}

.quantityText {
  color: var(--sys-color-content-secondary);
}
