.lines {
  border: 1px solid #f0f1f2;
  border-radius: 8px;
  margin-top: 1rem;
}

.containerLine {
  display: flex;
  align-items: center;
}

.line_container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sys-color-content-primary);
  height: 48px;
  justify-content: space-between;
  width: 70%;
}

.line_container:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sys-color-content-primary);
  height: 48px;
  justify-content: space-between;
  width: 100%;
}

.line {
  display: flex;
  color: var(--sys-color-content-primary);
  height: 48px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f1f2;
}

.line:last-child {
  display: flex;
  color: var(--sys-color-content-primary);
  height: 48px;
  justify-content: space-between;
  border-bottom: 0px transparent #f0f1f2;
}

.title {
  font-size: var(--sys-typography-body-md-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  line-height: var(--sys-typography-body-sm-line-height);
  font-weight: bolder;
  color: var(--sys-color-content-primary);
}

.subTitle {
  margin-left: 10px;
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  line-height: var(--sys-typography-label-xs-line-height);
  font-weight: var(--sys-typography-label-xs-font-weight);
  color: var(--sys-color-content-primary);
}

.subTitle span {
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  line-height: var(--sys-typography-label-xs-line-height);
  font-weight: var(--sys-typography-label-xs-font-weight);
  color: var(--sys-color-content-primary);
}

.success {
  display: flex;
  gap: 10px;
  font-size: var(--sys-typography-body-md-font-size);
  text-decoration: var(--sys-typography-body-md-text-decoration);
  letter-spacing: var(--sys-typography-body-md-letter-spacing);
  line-height: var(--sys-typography-body-md-line-height);
  font-weight: var(--sys-typography-body-md-font-weight);
  color: var(--sys-color-content-primary);
}

.error {
  font-size: var(--sys-typography-body-md-font-size);
  text-decoration: var(--sys-typography-body-md-text-decoration);
  letter-spacing: var(--sys-typography-body-md-letter-spacing);
  line-height: var(--sys-typography-body-md-line-height);
  font-weight: var(--sys-typography-body-md-font-weight);
  color: var(--sys-color-content-primary);
}

.subTitle {
  margin-left: 10px;
}

.logLoader {
  position: absolute;
  top: 43%;
  z-index: 1;
  left: 45%;
  border: 6px solid #f3f3f3;
  border-top: 6px solid var(--sys-color-content-interactive);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  animation: spin 2s linear infinite;
}

.loader {
  composes: subTitle;
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--sys-color-content-interactive);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.link {
  color: var(--comp-attention-box-color-foreground-danger);
}

.end_container {
  display: flex;
  justify-content: end;
}

.end {
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  letter-spacing: var(--sys-typography-label-sm-letter-spacing);
  line-height: var(--sys-typography-label-sm-line-height);
  font-weight: var(--sys-typography-label-sm-font-weight);
  color: var(--sys-color-content-secondary);
}

/* .flatfile__container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 100;
  background-color: #fff;
  margin: 0 auto;
}

.flatfile__container svg {
  fill: black !important;
  display: block;
  position: fixed;
  right: 0.2%;
  top: 0.5%;
  width: 26px;
  height: 26px;
} */

.idFGGD > div {
  color: black !important;
}

.zNMgJ {
  color: black !important;
}

.containerLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* backdrop-filter: blur(4px) brightness(90%); */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid var(--sys-color-content-interactive);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  animation: spin 2s linear infinite;
} */

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--sys-color-content-interactive);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
