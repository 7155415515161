.thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--comp-thumbnail-border-radius);
  color: var(--comp-thumbnail-color-foreground);
  outline: none;
  object-fit: cover;
}

.text {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
