.menuBurger {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
}

.page {
  max-height: 100vh;
  background: var(--sys-color-surface-01);
}

.content {
  display: inline-block;
  overflow: scroll;
}

.none {
  display: none;
}

.headbarRight > *:not(:first-child) {
  margin-left: 1em;
}

.drawer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 25rem;
  width: 420px;
  grid-row: 1 / 3;
  background: var(--sys-color-surface-00);
  z-index: 4;
  box-shadow: 2px 0px 8px rgb(21 45 80 / 10%);
}

/* Empty */
.containerEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.textEmpty {
  color: var(--sys-color-content-interactive);
  font-size: 21px;
  text-transform: uppercase;
  margin-top: 20px;
}

.icon {
  font-size: 4.8rem;
}

.titleEmpty {
  font-size: var(--sys-typography-title-lg-font-size);
  font-weight: var(--sys-typography-title-lg-font-weight);
  line-height: var(--sys-typography-title-lg-line-height);
}

.subTextEmptyInventory {
  font-size: var(--sys-typography-body-md-font-size);
  font-weight: var(--sys-typography-body-md-font-weight);
  line-height: var(--sys-typography-body-md-line-height);
  color: var(--ref-color-grey-cold-600);
  margin-top: 15px;
  text-align: center;
}

.subTextEmptyInventoryLink {
  font-size: var(--sys-typography-body-md-font-size);
  font-weight: 800;
  line-height: var(--sys-typography-body-md-line-height);
  text-align: center;
  color: var(--ref-color-grey-cold-800);
  text-decoration: underline;
  cursor: pointer;
}

.subTextEmpty {
  margin-top: 15px;
  text-align: center;
}

.mobileOverlay {
  background: #1d242d;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  top: 0px;
  position: fixed;
  z-index: 1;
}

/* Mobile */
@media screen and (max-width: 767px) {
  .headbar {
    padding: 0 10px;
  }

  .headbarRight {
    width: 40px;
  }

  .searchBar {
    width: inherit;
  }
}
