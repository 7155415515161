.container {
  position: relative;
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-default);
  border-radius: var(--sys-size-border-radius-md);
  padding: 0 var(--sys-size-4);
}

.container:hover {
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-hover);
}

.container:focus-within {
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-focused);
}

.containerOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--sys-size-2);
  width: 100%;
}

.option {
  display: flex;
  align-items: center;
  width: 90%;
}

.option > *:not(:first-child) {
  margin-left: 0.5em;
}

.optionName {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

.optionValues {
  max-width: 200px;
  color: var(--sys-color-content-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

.addContainer {
  display: flex;
  align-items: center;
  position: relative;
  height: var(--sys-size-10);
  width: 100%;
  cursor: pointer;
}

.addText {
  color: var(--sys-color-content-secondary);
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}
