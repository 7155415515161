.menu {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-right: 1px solid var(--comp-divider-color-default);
  background: var(--sys-color-surface-00);
  z-index: 4;
}

/* Top Menu */

.containerTopMenu {
  border-bottom: 1px solid var(--comp-divider-color-default);
}

.topMenu {
  padding: 12px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerSection {
  padding: 0.75em 1em 1em 1em;
  outline: none;
}

.containerSectionMobile {
  composes: containerSection;
  overflow-y: scroll;
  max-height: calc(100vh - 72px);
}

.menuSection {
  font-size: var(--sys-typography-label-lg-font-size);
  text-decoration: var(--sys-typography-label-lg-text-decoration);
  letter-spacing: var(--sys-typography-label-lg-letter-spacing);
  font-weight: var(--sys-typography-label-lg-font-weight);
  line-height: var(--sys-typography-label-lg-line-height);
  color: var(--sys-color-content-secondary);
  cursor: pointer;
  border-radius: 8px;
}

.menuSection:not(:first-child) {
  margin-top: 1em;
}

.sectionShrink {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 8px;
}

.containerSectionExpand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
}

.sectionExpand {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menuSubSection {
  margin-left: 2em;
}

.menuSubSectionItem {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0px 8px;
  margin-top: 0.125em;
  border-radius: 8px;
  font-size: var(--sys-typography-label-md-font-size);
  text-decoration: var(--sys-typography-label-md-text-decoration);
  letter-spacing: var(--sys-typography-label-md-letter-spacing);
  font-weight: var(--sys-typography-label-md-font-weight);
  line-height: var(--sys-typography-label-md-line-height);
  cursor: pointer;
}

.containerSectionExpand:hover,
.sectionShrink:hover,
.menuSubSectionItem:hover {
  background: var(--st-layer-color-default-hover);
  color: var(--sys-color-content-interactive) !important;
  border-radius: 8px;
}

.containerSectionExpand:focus {
  background: var(--st-layer-color-default-focused);
}

/* Bottom Menu */
.containerSectionBottom {
  composes: containerSection;
}

.containerSectionBottom > *:not(:first-child) {
  margin-top: 12px;
}

.menuProfile {
  display: flex;
  border-radius: 8px;
  gap: 0.5em;
}

.menuInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ellipsis {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menuName {
  composes: ellipsis;
  font-size: var(--sys-typography-label-md-font-size);
  text-decoration: var(--sys-typography-label-md-text-decoration);
  letter-spacing: var(--sys-typography-label-md-letter-spacing);
  font-weight: var(--sys-typography-label-md-font-weight);
  line-height: var(--sys-typography-label-md-line-height);
  font-weight: 500;
}

.menuSubtitle {
  composes: ellipsis;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
  color: var(--sys-color-content-secondary);
}

.popup {
  position: absolute;
  box-shadow: 0px 0px 8px rgba(2, 22, 75, 0.2);
  border-radius: 8px;
  cursor: pointer;
  background: var(--sys-color-surface-00);
  z-index: 1;
}

.menuPopup {
  composes: popup;
  left: calc(248px + 0.5em);
  bottom: 1em;
  width: 217px;
}

.menuCompanyList {
  composes: popup;
  left: calc(248px + 217px + 1em);
  bottom: 7em;
  width: 188px;
}

.companyList {
  overflow-y: scroll;
  max-height: 50vh;
}
