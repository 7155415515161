.container {
  position: relative;
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-default);
  border-radius: var(--sys-size-border-radius-md);
  padding: var(--sys-size-4);
}

.container:hover {
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-hover);
}

.container:focus-within {
  border: var(--sys-size-border-width-sm) solid var(--sys-color-border-focused);
}

.containerTag {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.4rem 0.2rem;
  border-radius: 4px;
  padding: 0.3rem 0.5rem;
  max-width: 95%;
  background: var(--ref-color-sky-200);
  color: var(--sys-color-content-interactive);
  height: 25px;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
}

.tagDisabled {
  composes: tag;
  color: var(--sys-color-content-disabled);
}

.tagError {
  composes: tag;
  color: var(--sys-color-content-danger);
}

.tagName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
