.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: var(--comp-notification-number-size-height);
  min-width: var(--comp-notification-number-size-fixed-width);
  padding: 0 var(--comp-notification-number-padding-dynamic);
  background: var(--comp-notification-number-color-background-info);
  border-radius: var(--comp-notification-number-border-radius);
  font-size: var(--sys-typography-notification-xs-font-size);
  text-decoration: var(--sys-typography-notification-xs-text-decoration);
  letter-spacing: var(--sys-typography-notification-xs-letter-spacing);
  font-weight: var(--sys-typography-notification-xs-font-weight);
  line-height: var(--sys-typography-notification-xs-line-height);
  color: var(--comp-notification-number-color-foreground-info);
}
