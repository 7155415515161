.errorLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.errorIcon {
  padding-top: 6px;
}

.errorMessage {
  color: var(--sys-color-content-danger);
  padding-left: 0.4rem;
}
