.attentionBox {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--comp-attention-box-border-radius);
  gap: 8px;
  padding: var(--comp-attention-box-padding-default);
  padding: 8px 8px 8px 16px;
  color: var(--comp-attention-box-color-foreground);
  cursor: default;
}

.title {
  width: fit-content;
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  letter-spacing: var(--sys-typography-label-sm-letter-spacing);
  font-weight: var(--sys-typography-label-sm-font-weight);
  line-height: var(--sys-typography-label-sm-line-height);
}

.description {
  width: fit-content;
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  font-weight: var(--sys-typography-label-xs-font-weight);
  line-height: var(--sys-typography-label-xs-line-height);
  padding-top: var(--comp-attention-box-padding-description-top);
}

.cross {
  position: absolute;
  right: var(--comp-attention-box-padding-default);
}

.cross > * {
  width: var(--comp-attention-box-size-icon);
  height: var(--comp-attention-box-size-icon);
}
