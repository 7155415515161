.container {
  position: absolute;
  right: 10px;
  max-height: 80vh;
  background: var(--sys-color-surface-00);
  box-shadow: 0px 2px 4px #c2c7ce;
  /* box-shadow: var(--sys-shadow-box-md-x) var(--sys-shadow-box-md-y) var(--sys-shadow-box-md-blur)
  var(----sys-shadow-box-md-color); */
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  padding: 0.5em 0;
  border-radius: 8px;
  width: 300px;
  max-width: 300px;
  white-space: nowrap;
  z-index: 2;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  height: var(--comp-list-item-size-height-default);
  padding: 0 var(--sys-size-4);
  gap: var(--comp-list-item-spacing-gap);
}

.content:active {
  box-shadow: 0px 2px 4px #c2c7ce;
  border-radius: 8px;
  height: var(--comp-list-item-size-height-default);
  outline: none;
}

.name {
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  line-height: var(--sys-typography-body-sm-line-height);
  font-weight: 400;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden {
  position: fixed;
  z-index: 1;
  top: 72px;
  width: 100vw;
  left: 0;
  height: 100vh;
}
