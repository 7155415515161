* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  overflow: hidden;
  height: 100vh;
}

.grid {
  display: grid;
  height: 100vh;
  width: 100%;
  align-items: baseline;
  padding: 0 40px;
  grid-column-gap: 24px;
}

.grid > div {
  background-color: var(--ref-color-pure-white);
}

/* Desktop */
@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 16px;
    align-items: stretch;
    grid-column-gap: 16px;
  }
}
