.containerLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--ref-color-pure-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid var(--sys-color-content-interactive);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
