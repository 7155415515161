.requester {
  overflow-x: scroll;
  padding-left: 1em;
}

.addFilter {
  max-width: 10em;
  padding: 0 0 0.5em 1em;
}

/* Fields */

.filterTextField {
  padding: 8px 16px;
}

/* Filters */

.containerFilters {
  display: flex;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  cursor: pointer;
  background: var(--sys-color-surface-01);
  border: 1px solid var(--sys-color-border-default);
  padding: 8px 8px 8px 16px;
  gap: 0.5em;
  margin-bottom: 0.5em;
  max-height: 42px;
}

.filterName,
.operator {
  min-width: max-content;
  color: var(--sys-color-content-secondary);
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  letter-spacing: var(--sys-typography-label-sm-letter-spacing);
  line-height: var(--sys-typography-label-sm-line-height);
  font-weight: 600;
}

.filterAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: var(--sys-color-surface-01);
  border: 1px solid var(--sys-color-border-default);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-left: none;
  padding: 8px;
}

.filterClose:hover,
.filterAdd:hover {
  background: var(--st-layer-color-default-hover);
}

.operator {
  background: var(--sys-color-surface-01);
}
