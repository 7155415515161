/* Tab stats */

.container {
  height: 105px;
  width: 100%;
  background: rgba(240, 242, 248, 0.44);
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text {
  margin-left: 1rem;
  margin-right: 0.5rem;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--sys-color-content-secondary);
}

.value {
  margin-top: 0.5rem;
  margin-left: 1rem;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: var(--sys-color-content-interactive);
}

.extension {
  margin-top: 0.3rem;
  margin-left: 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--sys-color-content-interactive);
}

.icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.iconSelected {
  position: absolute;
  right: 10px;
  top: 10px;
}

/* Dashboard stats */

.containerCard {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 40px 60px;
}

.card {
  width: 30%;
  padding: 20px;
}

.containerChart {
  padding: 40px 0px;
  padding-left: 10px;
  padding-right: 30px;
  max-width: 100%;
}

.containerList {
  margin: 1.5em 2.5em;
}

.list:not(:last-child) {
  margin-bottom: 100px;
}

.listName {
  margin-left: var(--sys-size-5);
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.listChartName {
  composes: listName;
  margin-left: 64px;
}

/* Mobile */
@media screen and (max-width: 767px) {
  .containerCard {
    padding: 0;
  }

  .card {
    width: 100%;
  }

  .containerList {
    margin: 1.5em 1em;
  }
}
