.container {
  display: flex;
  flex-direction: column;
}

.containerChildren {
  padding: 2.5em;
  height: calc(100vh - 70px);
}

.containerForm {
  width: 25em;
}

.containerForm > *:not(:last-child) {
  margin-bottom: 2.5em;
}

.title {
  font-size: var(--sys-typography-title-lg-font-size);
  text-decoration: var(--sys-typography-title-lg-text-decoration);
  letter-spacing: var(--sys-typography-title-lg-letter-spacing);
  line-height: var(--sys-typography-title-lg-line-height);
  font-weight: 600;
}

.subTitle {
  white-space: pre-line;
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
  color: var(--sys-color-content-primary);
}

.form {
  width: 25em;
}

.form > * {
  margin-bottom: 2em;
}

.avatar {
  width: 130px;
}

.action {
  font-size: var(--sys-typography-label-sm-font-size);
  text-decoration: var(--sys-typography-label-sm-text-decoration);
  letter-spacing: var(--sys-typography-label-sm-letter-spacing);
  line-height: var(--sys-typography-label-sm-line-height);
  color: var(--sys-color-content-interactive);
  font-weight: 600;
  cursor: pointer;
}

.email {
  font-size: var(--sys-typography-body-sm-font-size);
  text-decoration: var(--sys-typography-body-sm-text-decoration);
  letter-spacing: var(--sys-typography-body-sm-letter-spacing);
  font-weight: var(--sys-typography-body-sm-font-weight);
  line-height: var(--sys-typography-body-sm-line-height);
  color: var(--sys-color-content-secondary);
}

.info {
  font-size: var(--sys-typography-label-xs-font-size);
  text-decoration: var(--sys-typography-label-xs-text-decoration);
  letter-spacing: var(--sys-typography-label-xs-letter-spacing);
  font-weight: var(--sys-typography-label-xs-font-weight);
  line-height: var(--sys-typography-label-xs-line-height);
  color: var(--sys-color-content-secondary);
}
